import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { format, subMonths } from 'date-fns';
import { sumBy } from 'lodash';

import { useAuth } from '../../../../contexts/auth';
import resources from '../../../../resources';
import { ITypesTotals, TypeUser } from '../../../../resources/user';
import UserCardReport from '../../components/user-card-report';
import { getUserDescription } from '../../helpers/description';

const UserDashboard: React.FC = () => {
  const auth = useAuth();
  const { type } = useParams<{ type: TypeUser }>();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [userTypesTotals, setUserTypesTotals] = useState<ITypesTotals>();

  const centersDescription = useMemo(() => {
    if (
      !searchParams.has('centers') ||
      searchParams.getAll('centers').length === 0
    )
      return '';
    const centers = searchParams.getAll('centers');

    return `em ${centers.slice(0, 3).join(', ')}${
      centers.length > 3 ? `, +${centers.length - 3}` : ''
    }`;
  }, [searchParams]);
  const userTypeDescription = useMemo(() => getUserDescription(type!), [type]);

  const generateLink = (link: string) => {
    if (Array.from(searchParams.keys()).length > 0) {
      return `${link}?${searchParams.toString()}`;
    }
    return link;
  };

  const getParams = (query: {
    fromDate: Date | string | null;
    toDate: Date | string | null;
    centers: string[] | null;
  }) => ({
    fromDate: query.fromDate
      ? format(new Date(query.fromDate), 'yyyy-MM-dd')
      : format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    toDate: query.toDate
      ? format(new Date(query.toDate), 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd'),
    codCenters: query.centers ?? [],
  });

  const fetchUserTypesTotals = useCallback(async () => {
    let centers = searchParams.getAll('centers') ?? [];
    if (centers.length === 0) {
      centers = auth.user?.centers.map(({ id }) => id) ?? [];
    }

    const params = getParams({
      centers,
      fromDate: searchParams.get('fromDate'),
      toDate: searchParams.get('toDate'),
    });
    const response = await resources.use('user').userTypesTotals(params);

    if (response.status === 'OK') {
      setUserTypesTotals(response.payload);
    }
  }, [auth.user?.centers, searchParams]);

  const activeInBaseTotals = useMemo(
    () =>
      sumBy(
        (userTypesTotals?.totalInBase ?? []).filter((t) => t.typeUser === type),
        (t) => +t.activeInBase
      ),
    [type, userTypesTotals?.totalInBase]
  );
  const totalInAppTotals = useMemo(
    () =>
      sumBy(
        (userTypesTotals?.totalInApp ?? []).filter((t) => t.typeUser === type!),
        (t) => +t.activeInApp
      ),
    [type, userTypesTotals?.totalInApp]
  );

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchUserTypesTotals()]).finally(() => {
      setLoading(false);
    });
  }, [fetchUserTypesTotals]);

  return (
    <Grid container>
      <Grid
        item
        md={6}
      >
        <UserCardReport
          description={`Ver lista de ${userTypeDescription} ${centersDescription}`}
          label={`${userTypeDescription} na Base`}
          loading={loading}
          to={generateLink('./base')}
          value={activeInBaseTotals}
        />
      </Grid>
      <Grid
        item
        md={6}
      >
        <UserCardReport
          description={`Total de ${userTypeDescription} ativos ${centersDescription}`}
          label="Ativos no app"
          loading={loading}
          to={generateLink('./active')}
          value={totalInAppTotals}
        />
      </Grid>
    </Grid>
  );
};

export default UserDashboard;
