import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { ArrowBack } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';

interface IHeaderForm {
  title: ReactNode;
}

const HeaderForm: React.FC<IHeaderForm> = ({ title }) => (
  <Stack
    alignItems="center"
    direction="row"
    spacing={1}
  >
    <IconButton
      component={Link}
      to="../"
    >
      <ArrowBack />
    </IconButton>
    <span>{title}</span>
  </Stack>
);

export default HeaderForm;
