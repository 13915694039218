import { Box, Chip, Grid, Stack, Typography } from '@mui/material';

import ChartUniqueBar from '../chart-bar';

interface IQualityProperty {
  name: string;
  max: number;
  value: number;
  average: number;
}

export interface IQualityValueChart {
  fat: IQualityProperty;
  protein: IQualityProperty;
  cbt: IQualityProperty;
  ccs: IQualityProperty;
  est: IQualityProperty;
  esd: IQualityProperty;
}

export interface IQualityCharts {
  values: IQualityValueChart;
}

const QualityCharts: React.FC<IQualityCharts> = ({
  values: { fat, cbt, ccs, esd, est, protein },
}) => (
  <Stack spacing={2}>
    <Grid
      container
      justifyContent="space-around"
      spacing={2}
      sx={{
        minHeight: 350,
        position: 'relative',
        flexWrap: { xs: 'wrap', sm: 'nowrap' },
      }}
    >
      <Grid
        item
        md={2}
      >
        <Stack
          spacing={2}
          sx={{
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Chip
            label="Gordura"
            size="small"
            sx={{ bgcolor: 'grey.700', color: 'white', alignSelf: 'center' }}
          />
          <ChartUniqueBar
            avg={fat.average ?? 0}
            max={fat.max ?? 0}
            name={fat.name ?? 0}
            value={fat.value ?? 0}
          />
        </Stack>
      </Grid>
      <Grid
        item
        md={2}
      >
        <Stack
          spacing={2}
          sx={{
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Chip
            label="Proteína"
            size="small"
            sx={{ bgcolor: 'grey.700', color: 'white', alignSelf: 'center' }}
          />
          <ChartUniqueBar
            avg={protein.average ?? 0}
            max={protein.max ?? 0}
            name={protein.name ?? 0}
            value={protein.value ?? 0}
          />
        </Stack>
      </Grid>
      <Grid
        item
        md={2}
      >
        <Stack
          spacing={2}
          sx={{
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Chip
            label="CBT"
            size="small"
            sx={{ bgcolor: 'grey.700', color: 'white', alignSelf: 'center' }}
          />
          <ChartUniqueBar
            avg={cbt.average ?? 0}
            max={cbt.max ?? 0}
            name={cbt.name ?? 0}
            value={cbt.value ?? 0}
            valuesOptions={{
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
          />
        </Stack>
      </Grid>
      <Grid
        item
        md={2}
      >
        <Stack
          spacing={2}
          sx={{
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Chip
            label="CCS"
            size="small"
            sx={{ bgcolor: 'grey.700', color: 'white', alignSelf: 'center' }}
          />
          <ChartUniqueBar
            avg={ccs.average ?? 0}
            max={ccs.max ?? 0}
            name={ccs.name ?? 0}
            value={ccs.value ?? 0}
            valuesOptions={{
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
          />
        </Stack>
      </Grid>
      <Grid
        item
        md={2}
      >
        <Stack
          spacing={2}
          sx={{
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Chip
            label="EST"
            size="small"
            sx={{ bgcolor: 'grey.700', color: 'white', alignSelf: 'center' }}
          />
          <ChartUniqueBar
            avg={est.average ?? 0}
            max={est.max ?? 0}
            name={est.name ?? 0}
            value={est.value ?? 0}
          />
        </Stack>
      </Grid>
      <Grid
        item
        md={2}
      >
        <Stack
          spacing={2}
          sx={{
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Chip
            label="ESD"
            size="small"
            sx={{ bgcolor: 'grey.700', color: 'white', alignSelf: 'center' }}
          />
          <ChartUniqueBar
            avg={esd.average ?? 0}
            max={esd.max ?? 0}
            name={esd.name ?? 0}
            value={esd.value ?? 0}
          />
        </Stack>
      </Grid>
    </Grid>
    <Stack
      alignItems="center"
      direction="row"
      mt={2}
      mx={2}
      spacing={1}
    >
      <Box
        sx={{
          height: 3,
          width: 15,
          borderRadius: 3,
          bgcolor: 'secondary.main',
        }}
      />
      <Typography sx={{ fontSize: 10, color: 'grey.500' }}>
        Indicador do padrão satisfatório IN76
      </Typography>
    </Stack>
  </Stack>
);

export default QualityCharts;
