import { useCallback, useState } from 'react';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { Link } from 'react-router-dom';

import { Key, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';

import CheckPasswordPolicy from '../../../../components/check-password-policy';
import Validator, { resolveAdapter } from '../../../../libraries/validator';
import validatePasswordPolicy, {
  IPasswordPolicy,
} from '../../../../libraries/validator/validate-password-policy';

interface IConfirmPasswordRecoveryForm {
  onSuccess(values: any): Promise<void>;
}
export interface INewPasswordPayload {
  confirmationCode: string;
  username: string;
  password: string;
  confirmPassword: string;
}

const passwordSecurityRule: IPasswordPolicy = {
  maxLength: 0,
  minLength: 8,
  minLowercaseCharacters: 1,
  minUppercaseCharacters: 1,
  minSpecialCharacters: 1,
  minNumericCharacters: 0,
};

const rememberPasswordSchemaValidation = Validator.object().shape({
  confirmationCode: Validator.string().nullable().required().label('Usuário'),
  password: Validator.string()
    .nullable()
    .required()
    .test('password-policy', validatePasswordPolicy(passwordSecurityRule))
    .label('Senha'),
  confirmPassword: Validator.string()
    .required()
    .oneOf([Validator.ref('password'), null], 'Senha não confere')
    .label('Confirmação de senha'),
});

const ConfirmPasswordRecoveryForm: React.FC<IConfirmPasswordRecoveryForm> = ({
  onSuccess,
}) => {
  const form = useForm({
    resolver: resolveAdapter(rememberPasswordSchemaValidation),
  });
  const [showVisibility, setVisibility] = useState({
    password: false,
    confirmationCode: false,
  });

  const [loading, setLoading] = useState(false);

  const password = form.watch('password');

  const onSuccessHandler = useCallback(
    async (values: any) => {
      setLoading(true);
      await onSuccess(values);
      setLoading(false);
    },
    [onSuccess]
  );

  const handleToggleVisibility = (context: 'password' | 'confirmationCode') => {
    setVisibility((old: { password: boolean; confirmationCode: boolean }) => ({
      ...old,
      [context]: !old[context],
    }));
  };

  return (
    <FormContainer
      defaultValues={{
        username: undefined,
        confirmationCode: undefined,
        password: undefined,
        confirmPassword: undefined,
      }}
      formContext={form}
      onSuccess={onSuccessHandler}
    >
      <Stack spacing={3}>
        <Typography
          sx={{ textAlign: 'center' }}
          variant="caption"
        >
          Preencha para alterar a senha
        </Typography>
        <Stack spacing={1}>
          <TextFieldElement
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Key color="inherit" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => handleToggleVisibility('confirmationCode')}
                  >
                    {showVisibility.confirmationCode ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="código de confirmação"
            name="confirmationCode"
            required
            type={showVisibility.confirmationCode ? 'text' : 'password'}
          />
          <TextFieldElement
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock color="inherit" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => handleToggleVisibility('password')}
                  >
                    {showVisibility.password ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="nova senha"
            name="password"
            required
            type={showVisibility.password ? 'text' : 'password'}
          />
          <TextFieldElement
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock color="inherit" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => handleToggleVisibility('password')}
                  >
                    {showVisibility.password ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="confirmar nova senha"
            name="confirmPassword"
            required
            type={showVisibility.password ? 'text' : 'password'}
          />
        </Stack>
        <CheckPasswordPolicy
          rules={passwordSecurityRule}
          sx={{ mt: 1 }}
          value={password}
        />
        <Stack spacing={1}>
          <Collapse
            in={loading}
            sx={{ alignSelf: 'center' }}
          >
            <CircularProgress size={20} />
          </Collapse>
          <Collapse in={!loading}>
            <Button
              color="primary"
              fullWidth
              sx={{ borderRadius: 20 }}
              type="submit"
              variant="contained"
            >
              Alterar senha
            </Button>
          </Collapse>
          <Button
            color="primary"
            component={Link}
            sx={{ borderRadius: 20 }}
            to="/auth/login"
          >
            Voltar ao login
          </Button>
        </Stack>
      </Stack>
    </FormContainer>
  );
};

export default ConfirmPasswordRecoveryForm;
