import { darken, lighten, SxProps, Theme } from '@mui/material';

import { makeBackground } from '../../styles';

export const wrapperStyles: SxProps<Theme> = (theme) => ({
  position: { xs: 'relative', md: 'inherit' },
  // top: 0,
  width: { xs: '100vw', md: 295 },
  height: { xs: 'auto', md: '100%' },
  background: makeBackground(
    theme.palette.primary.light ?? theme.palette.primary.main,
    theme.palette.primary.dark ?? theme.palette.primary.main,
    19
  ),
  color: 'primary.contrastText',
  marginTop: theme.mixins.toolbar.height,
  zIndex: 1,
});

export const menuItemStyles: SxProps<Theme> = (theme) => ({
  background: 'transparent',
  justifyContent: 'flex-start!important',
  fontSize: 14,
  textTransform: 'inherit',
  borderRadius: 20,
  '&:hover,&.active': {
    background: makeBackground(
      theme.palette.secondary.light,
      theme.palette.secondary.dark
    ),
  },
  '& .MuiButton-startIcon': {
    mr: 2,
  },
});

export const footerMenuLogoutStyles: SxProps<Theme> = (theme) => ({
  position: 'fixed',
  bottom: 0,
  px: 3,
  py: 2,
  width: { xs: '100vw', md: 295 },
  color: theme.palette.primary.contrastText,
  bgcolor: darken(theme.palette.primary.main, 0.2),
  borderRadius: 0,
  justifyContent: 'flex-start!important',
  border: 'none',
  borderTop: `1px solid ${lighten(theme.palette.primary.main, 0.2)}`,
  '&:hover, &:focus': {
    bgcolor: darken(theme.palette.primary.main, 0.3),
  },
  zIndex: theme.zIndex.appBar,
});
