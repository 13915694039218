import Http from '../services/http';
import { IResourceResponse } from './response';

interface IGetPriceByIdentity {
  identity: string;
  fromDate: string | null;
  toDate: string | null;
}
interface IGetPriceDataOverview {
  fromDate: string | null;
  toDate: string | null;
  centers: string[];
  supervisors?: string[];
}

export interface IPriceOverview {
  priceData: {
    priceAverage: number;
    priceVariation: number | null;
  };
  graphicCodCenter: { codCenter: string; average: number }[];
  graphicMonth: { year: number; month: number; average: number }[];
}

export interface IPrice {
  price: number;
  lastResult: boolean;
}

export interface IPriceResources {
  getPrice({
    identity,
  }: IGetPriceByIdentity): Promise<IResourceResponse<IPrice>>;
  getPriceDataOverview({
    centers,
    fromDate,
    supervisors,
    toDate,
  }: IGetPriceDataOverview): Promise<IResourceResponse<IPriceOverview>>;
}

const price: IPriceResources = {
  async getPriceDataOverview({ supervisors, fromDate, toDate, centers }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_PRICE_URL}/priceDataOverview`,
      {
        params: {
          ...((supervisors ?? [])?.length > 0
            ? { codSupervisor: (supervisors ?? []).join(',') }
            : {}),
          codCenter: centers.join(','),
          fromDate,
          toDate,
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getPrice({ identity, fromDate, toDate }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_PRICE_URL}/priceDataUserCenter`,
      {
        params: {
          identifierProducer: identity,
          fromDate,
          toDate,
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
};

export default price;
