import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { useNavigate, useParams } from 'react-router-dom';

import { Collapse } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';

import Content from '../../../../components/layouts/content';
import Loading from '../../../../components/loading';
import { resolveAdapter } from '../../../../libraries/validator';
import resources from '../../../../resources';
import ContentForm, { schemaValidation } from '../../components/content-form';
import HeaderForm from '../../components/header-form';

const UpdateContent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [tags, setTags] = useState<string[]>([]);
  const [centers, setCenters] = useState<string[]>([]);

  const navigate = useNavigate();
  const form = useForm({
    resolver: resolveAdapter(schemaValidation),
  });

  const fetchContent = useCallback(async () => {
    const response = await resources.use('content').getById(id!);

    if (response.status === 'OK') {
      form.reset({
        ...response.payload,
        codeCenter:
          (response.payload?.codeCenter ?? []).length === 0
            ? ['Todos']
            : (response.payload?.codeCenter ?? []).map((c) => c),
      });
    } else {
      navigate('../');
    }
  }, [id, form, navigate]);

  const uploadImages = useCallback(async (file: File) => {
    const response = await resources
      .use<'content'>('content')
      ?.uploadImage(file);
    if (response.status === 'OK') return response.payload;
    return undefined;
  }, []);

  const update = useCallback(
    async (values: any) => {
      let payload: any = {
        type: values.type,
        lang: values.lang,
        codeCenter: (values.codeCenter ?? [])
          .map((c: any) => c)
          .filter((v: any) => v !== 'Todos'),
        publishDate: format(parseISO(values.publishDate), 'yyyy-MM-dd'),
        tags: values.tags ?? [],
      };

      if (payload.type === 'feed') {
        payload.feed = {
          ...values.feed,
          imageUrl:
            values.feed.imageUrl instanceof File
              ? await uploadImages(values.feed.imageUrl)
              : values.feed.imageUrl,
        };
      }

      if (payload.type === 'image') {
        payload.image = {
          ...values.image,
          imageUrl:
            values.image.imageUrl instanceof File
              ? await uploadImages(values.image.imageUrl)
              : values.image.imageUrl,
        };
      }

      if (payload.type === 'youtube') {
        payload.youtube = values.youtube;
      }

      payload = schemaValidation.cast(payload, {
        stripUnknown: true,
      }) as any;

      if ((payload.codeCenter ?? []).length === 0) {
        delete payload.codeCenter;
      }

      const response = await resources.use('content').update(id!, payload);

      if (response.status === 'OK') {
        navigate('../');
        snackbar.enqueueSnackbar('Conteúdo atualizado com sucesso', {
          variant: 'success',
        });
      } else {
        snackbar.enqueueSnackbar('Não foi possivel atualizar o conteúdo', {
          variant: 'error',
        });
      }
    },
    [id, uploadImages, navigate, snackbar]
  );

  const fetchTags = useCallback(async () => {
    const response = await resources.use('content').getTags();
    if (response.status === 'OK') {
      setTags(response.payload ?? []);
    }
  }, []);

  const fetchCenters = useCallback(async () => {
    const response = await resources.use('property').getCenters();
    if (response.status === 'OK') {
      setCenters(response.payload?.map(({ codCenter }) => codCenter) ?? []);
    }
  }, []);

  const values = form.watch();

  const title = useMemo(() => {
    if (values.type === 'feed') return values.feed.title;
    if (values.type === 'image') return `Imagem`;
    if (values.type === 'youtube') return `Video`;
    return null;
  }, [values]);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchTags(), fetchCenters(), fetchContent()]).finally(() => {
      setLoading(false);
    });
  }, [fetchContent, fetchCenters, fetchTags]);

  return (
    <Content title={<HeaderForm title={title} />}>
      <FormContainer
        formContext={form}
        onSuccess={update}
      >
        <Loading loading={loading} />
        <Collapse
          in={!loading}
          unmountOnExit
        >
          <ContentForm
            defaultCenters={centers}
            defaultTags={tags}
            onImageUpload={uploadImages}
          />
        </Collapse>
      </FormContainer>
    </Content>
  );
};
export default UpdateContent;
