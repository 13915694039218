import { GlobalStylesProps } from '@mui/material';

import theme from '.';

const globalStyle: GlobalStylesProps['styles'] = {
  body: {
    minHeight: '100vh',
    backgroundColor: theme.palette.grey[200],
  },
  html: {
    WebkitFontSmoothing: 'auto',
    minHeight: '100%',
  },
  '#root': {
    minHeight: '100vh',
  },
};

export default globalStyle;
