import schemas from './schemas';

type IResources = typeof schemas;
type IResourceContext = keyof IResources;

interface IResourceProvider {
  use: <T extends IResourceContext>(context: T) => IResources[T];
}

const resources: IResourceProvider = {
  use(context) {
    if (!schemas[context]) {
      throw new Error('Resource not avaliable');
    }
    return schemas[context];
  },
};

export default resources;
