import { ReactNode, useMemo } from 'react';

import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material';

import { numberFormat } from '../../../../helpers/number';

export interface IReportNumbersCardItem {
  title: ReactNode;
  value: number;
  prefix?: string;
  suffix?: string;
  compare?: {
    value: number;
    type: 'value' | 'percentage';
  };
  valueFormatOption?: Intl.NumberFormatOptions;
  loading?: boolean;
}

interface IIconCompare {
  variant: 'positive' | 'negative' | 'neutral';
}

const IconCompare: React.FC<IIconCompare> = ({ variant }) => {
  const theme = useTheme();

  return (
    <Box>
      <svg
        height="15"
        viewBox="0 0 12.295 15.807"
        width="12"
      >
        <path
          d="M8.513 3 5 6.5h2.634v6.16h1.757V6.5h2.634z"
          fill={
            variant === 'negative'
              ? theme.palette.secondary.dark
              : theme.palette.grey[300]
          }
          transform="translate(-5 -3)"
        />
        <path
          d="M15.391 16.156V10h-1.757v6.156H11l3.513 3.5 3.513-3.5z"
          fill={
            variant === 'positive'
              ? theme.palette.secondary.dark
              : theme.palette.grey[300]
          }
          transform="translate(-5.731 -3.852)"
        />
      </svg>
    </Box>
  );
};

const ReportNumbersCardItem: React.FC<IReportNumbersCardItem> = ({
  title,
  value,
  prefix,
  suffix,
  valueFormatOption,
  compare,
  loading,
}) => {
  const formatValue = useMemo(() => {
    if (Number.isNaN(value)) return ' - ';
    return numberFormat(value, valueFormatOption);
  }, [value, valueFormatOption]);

  return (
    <Box>
      <Stack direction="column">
        {loading ? (
          <Skeleton
            animation="wave"
            height={10}
            sx={{ maxWidth: 1 }}
            width={25}
          />
        ) : (
          <Typography
            sx={{ fontSize: 11 }}
            variant="caption"
          >
            {title}
          </Typography>
        )}
        {loading ? (
          <Skeleton
            animation="wave"
            height={40}
            sx={{ maxWidth: 1 }}
            width={40}
          />
        ) : (
          <Typography
            sx={{ fontSize: 20, fontWeight: 'bold' }}
            variant="body1"
          >
            {prefix}
            {formatValue}
            {suffix}
          </Typography>
        )}
        {compare && (
          <Stack
            alignItems="center"
            direction="row"
            spacing={0.5}
          >
            {loading ? (
              <Skeleton
                animation="wave"
                width={20}
              />
            ) : (
              <>
                <IconCompare
                  variant={
                    compare.value < 0
                      ? 'negative'
                      : compare.value > 1
                      ? 'positive'
                      : 'neutral'
                  }
                />
                <Typography
                  sx={{
                    color: 'secondary.main',
                    fontSize: 11,
                  }}
                >
                  {compare.type === 'value' && 'R$'}
                  {compare.value > 0 ? '+ ' : compare.value < 0 ? '- ' : ''}
                  {Math.abs(compare.value ?? 0).toFixed(0)}
                  {compare.type === 'percentage' && '%'}*
                </Typography>
              </>
            )}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default ReportNumbersCardItem;
