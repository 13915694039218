import Http from '../services/http';
import { IResourceResponse } from './response';

interface IPropertyAcceptReportDownloadPayload {
  fromDate: string | null;
  toDate: string | null;
  codCenters: string[] | null;
}

interface IGetPropertiesByCpf {
  cpf: string;
}

export interface IProperty {
  id: string;
  name: string;
  address: string;
  cep: string;
  city: string;
  dairyRegion: string;
  complement: string;
  country: string;
  hectares: string;
  linkedDairybox: string;
  mailbox: string;
  mainSecondaryProperty: string;
  latitude: string;
  longitude: string;
  mediumHerdSize: string;
  number: string;
  responsibleFieldAgent: string;
  routeDescription: string;
  state: string;
  supplierCode: string;
  typeProduction: string;
  createdAt: string;
  cnpj: string;
  areaDairyActivity: string;
  favorite: string;
  mesoregion: string;
  numberProducerRural: string;
  totalArea: string;
  isActive: true;
  supplierDairy: string;
  nameVeterinary: string;
  code: string;
  codeCenter: string;
  nameQualitySupervisor: string;
  namePurchasingSupervisor: string;
}

export interface IPropertyResources {
  getCenters(): Promise<IResourceResponse<{ codCenter: string }[]>>;
  getPropertiesByCpf({
    cpf,
  }: IGetPropertiesByCpf): Promise<IResourceResponse<IProperty[]>>;
  propertiesActiveReportDownload({
    fromDate,
    toDate,
    codCenters,
  }: Partial<IPropertyAcceptReportDownloadPayload>): Promise<
    IResourceResponse<any>
  >;
}

const properties: IPropertyResources = {
  async getPropertiesByCpf({ cpf }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_PROPERTY_URL}/propertyCpf/${cpf}`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(({ codePiracanjuba, ...property }: any) => ({
          ...property,
          code: codePiracanjuba,
        })),
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getCenters() {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_PROPERTY_URL}/property/center`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async propertiesActiveReportDownload({ codCenters, fromDate, toDate }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_PROPERTY_URL}/reports/propertyActive`,
      {
        responseType: 'blob',
        params: {
          fromDate,
          toDate,
          codCenter: codCenters?.join(','),
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
};

export default properties;
