import Http from '../services/http';
import { IResourceResponse } from './response';

interface IGetVolumeByProducerPayload {
  fromDate: string | null;
  toDate: string | null;
  identity: string;
}
export interface IProperty {
  id: string;
  name: string;
  address: string;
  cep: string;
  city: string;
  dairyRegion: string;
  complement: string;
  country: string;
  hectares: string;
  linkedDairybox: string;
  mailbox: string;
  mainSecondaryProperty: string;
  latitude: string;
  longitude: string;
  mediumHerdSize: string;
  number: string;
  responsibleFieldAgent: string;
  routeDescription: string;
  state: string;
  supplierCode: string;
  typeProduction: string;
  createdAt: string;
  cnpj: string;
  areaDairyActivity: string;
  favorite: string;
  mesoregion: string;
  numberProducerRural: string;
  totalArea: string;
  isActive: true;
  supplierDairy: string;
  nameVeterinary: string;
  code: string;
  codeCenter: string;
  nameQualitySupervisor: string;
  namePurchasingSupervisor: string;
}

interface IGetVolumeDataOverview {
  fromDate: string | null;
  toDate: string | null;
  centers: string[];
  supervisors?: string[];
}

interface IGetVolumeDayOverview {
  fromDate: string | null;
  toDate: string | null;
  centers: string[];
  supervisors?: string[];
}

export interface IVolumeOverview {
  volumeData: {
    totalVolume: number;
    volumeVariation: number | null;
  };
  graphicMonth: {
    year: number;
    month: number;
    totalVolume: number;
  }[];
}
export interface IVolumeDayOverview {
  graphicDay: {
    date: string;
    totalVolume: number;
  }[];
}

export interface IPropertyResources {
  getVolumeByProducer({
    fromDate,
    toDate,
    identity,
  }: IGetVolumeByProducerPayload): Promise<
    IResourceResponse<{ totalVolume: number }>
  >;
  getVolumeDataOverview({
    centers,
    fromDate,
    supervisors,
    toDate,
  }: IGetVolumeDataOverview): Promise<IResourceResponse<IVolumeOverview>>;
  getVolumeGraphicDayOverview({
    centers,
    fromDate,
    supervisors,
    toDate,
  }: IGetVolumeDayOverview): Promise<IResourceResponse<IVolumeDayOverview>>;
}

const volume: IPropertyResources = {
  async getVolumeGraphicDayOverview({
    supervisors,
    fromDate,
    toDate,
    centers,
  }) {
    const { status, data } = await Http.get<IVolumeDayOverview>(
      `${process.env.REACT_APP_API_VOLUME_URL}/volumeGraphicDayOverview`,
      {
        params: {
          ...((supervisors ?? [])?.length > 0
            ? { codSupervisor: (supervisors ?? []).join(',') }
            : {}),
          codCenter: centers.join(','),
          fromDate,
          toDate,
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getVolumeDataOverview({ supervisors, fromDate, toDate, centers }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_VOLUME_URL}/volumeDataOverview`,
      {
        params: {
          ...((supervisors ?? [])?.length > 0
            ? { codSupervisor: (supervisors ?? []).join(',') }
            : {}),
          codCenter: centers.join(','),
          fromDate,
          toDate,
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getVolumeByProducer({ identity, toDate, fromDate }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_VOLUME_URL}/volumeTotalByIdentifierProducer`,
      {
        params: {
          identifierProducer: identity,
          toDate,
          fromDate,
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          totalVolume: data.totalVolume ?? 0,
        },
      };
    }
    return {
      status: 'FAIL',
    };
  },
};

export default volume;
