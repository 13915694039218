import React from 'react';
import { Route, Routes } from 'react-router-dom';

import CreateNotification from './elements/create';
import DetailNotification from './elements/detail';
import NotificationList from './elements/notification-list';

const Notification: React.FC = () => (
  <Routes>
    <Route
      element={<NotificationList />}
      index
    />
    <Route
      element={<CreateNotification />}
      path="create"
    />
    <Route
      element={<DetailNotification />}
      path=":id"
    />
  </Routes>
);

export default Notification;
