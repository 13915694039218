import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { pt } from 'yup-locale-pt';

const Validator = Yup;

export const resolveAdapter = yupResolver;

Validator.setLocale(pt);

export default Validator;
