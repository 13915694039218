import React, { useEffect, useState } from 'react';

import { Photo } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Button, IconButton, Popover, Stack, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface UploadImagePopoverProps {
  anchor: TAnchor;
  onSubmit: (data: TUploadImageData, insert: boolean) => void;
}

type UploadImagePopoverState = {
  anchor: TAnchor;
  isCancelled: boolean;
};

type TUploadImageData = {
  file?: File;
};

type TAnchor = HTMLElement | null;

const cardPopoverStyles = makeStyles({
  root: {
    padding: 10,
    maxWidth: 450,
  },
  textField: {
    width: '100%',
  },
  input: {
    display: 'none',
  },
});

const UploadImagePopover: React.FC<UploadImagePopoverProps> = (props) => {
  const classes = cardPopoverStyles(props);
  const { anchor, onSubmit } = props;
  const [state, setState] = useState<UploadImagePopoverState>({
    anchor: null,
    isCancelled: false,
  });
  const [data, setData] = useState<TUploadImageData>({});

  useEffect(() => {
    setState({
      anchor,
      isCancelled: false,
    });
    setData({
      file: undefined,
    });
  }, [anchor]);

  return (
    <Popover
      anchorEl={state.anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={state.anchor !== null}
      // PaperProps={{ sx: { p: 3 } }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Stack
        spacing={2}
        sx={{ p: 3 }}
      >
        <TextField
          // className={classes.textField}
          disabled
          size="small"
          value={data.file?.name || ''}
        />
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          onChange={(event) => {
            if (event.target?.files?.[0]) {
              setData({
                ...data,
                file: event.target.files[0],
              });
            }
          }}
          type="file"
        />
        <label htmlFor="contained-button-file">
          <Button
            color="primary"
            component="span"
            fullWidth
            startIcon={<Photo />}
            variant="contained"
          >
            Escolher imagem
          </Button>
        </label>
        <Stack
          direction="row"
          justifyContent="flex-end"
        >
          <IconButton
            onClick={() => {
              setState({
                anchor: null,
                isCancelled: true,
              });
            }}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setState({
                anchor: null,
                isCancelled: false,
              });
              onSubmit(data, !state.isCancelled);
            }}
          >
            <DoneIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Popover>
  );
};

export default UploadImagePopover;
