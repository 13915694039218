import Http from '../services/http';
import { IResourceResponse } from './response';

interface IHerdCompositionOverviewPayload {
  fromDate: string | null;
  toDate: string | null;
  codCenters?: string[] | null;
  codePiracanjuba?: string | null;
}

export interface IHerdCompositionOverviewResponse {
  totalRecords: number;
  female: Female;
  male: Male;
  totalFemale: number;
  totalMale: number;
  totalAnimals: number;
}

export interface IRange {
  value: number;
  description: string;
}

export interface Female {
  rangeOne: IRange;
  rangeTwo: IRange;
  rangeThree: IRange;
  rangeFour: IRange;
  dryCow: IRange;
  lactatingCow: IRange;
}

export interface Male {
  rangeOne: IRange;
  rangeTwo: IRange;
  bull: IRange;
}

export interface IAnimalResources {
  getHerdCompositionOverview({
    fromDate,
    toDate,
    codCenters,
  }: IHerdCompositionOverviewPayload): Promise<IResourceResponse<any>>;
}

const animal: IAnimalResources = {
  async getHerdCompositionOverview({
    fromDate,
    toDate,
    codCenters,
    codePiracanjuba,
  }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_ANIMAL_URL}/herdCompositionSum`,
      {
        params: {
          fromDate,
          toDate,
          codCenter: codCenters?.join(','),
          codePiracanjuba,
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
};

export default animal;
