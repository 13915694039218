import ChartLine from '../chart-line';

export interface IVolumeDayValuesChart {
  max?: number;
  items: {
    name: string;
    value: number;
  }[];
}

interface IVolumeDayChart {
  values: IVolumeDayValuesChart;
}

const VolumeDayChart: React.FC<IVolumeDayChart> = ({ values }) => (
  <ChartLine values={values} />
);

export default VolumeDayChart;
