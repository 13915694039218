import animal from './animal';
import content from './content';
import notification from './notification';
import price from './price';
import property from './property';
import quality from './quality';
import user from './user';
import util from './util';
import volume from './volume';

const schemas = {
  notification,
  user,
  content,
  property,
  volume,
  quality,
  price,
  util,
  animal,
};

export default schemas;
