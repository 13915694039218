import React, { useCallback, useEffect, useState } from 'react';
import {
  Link,
  useOutletContext,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { ArrowForward, InfoOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Collapse,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import Loading from '../../../../../../components/loading';
import { useAuth } from '../../../../../../contexts/auth';
import { formatDate } from '../../../../../../helpers/date';
import resources from '../../../../../../resources';
import { IPagination } from '../../../../../../resources/pagination';
import { IUser } from '../../../../../../resources/user';
import { IUserLayoutContext } from '../../layout';

const Header: React.FC = () => (
  <Grid
    alignItems="flex-end"
    container
    spacing={2}
  >
    <Grid
      item
      sx={{ width: 56, xs: { display: 'none' } }}
    />
    <Grid
      item
      md={2.5}
      xs={8}
    >
      <Typography
        sx={{
          display: 'block',
          fontSize: 13,
          color: 'grey.600',
        }}
        variant="caption"
      >
        Usuário
      </Typography>
    </Grid>
    <Grid
      item
      md={1.5}
      xs={12}
    >
      <Typography
        sx={{
          display: 'block',
          fontSize: 13,
          color: 'grey.600',
        }}
        variant="caption"
      >
        Última atividade
      </Typography>
    </Grid>
    <Grid
      item
      md={1}
      xs={12}
    >
      <Typography
        sx={{
          display: 'block',
          fontSize: 13,
          color: 'grey.600',
        }}
        variant="caption"
      >
        Versão do App
      </Typography>
    </Grid>
    <Grid
      item
      md={3.5}
      xs={12}
    >
      <Typography
        sx={{
          display: 'block',
          fontSize: 13,
          color: 'grey.600',
        }}
        variant="caption"
      >
        Cadastro
      </Typography>
    </Grid>
    <Grid
      item
      md
      xs={12}
    >
      <Typography
        sx={{
          display: 'block',
          fontSize: 13,
          color: 'grey.600',
        }}
        variant="caption"
      >
        Convite
      </Typography>
    </Grid>
    <Grid
      alignSelf="center"
      item
      md="auto"
      xs={12}
    />
  </Grid>
);

const UserListItem: React.FC<IUser> = ({
  name,
  cpf,
  userNameCognito,
  invitation,
  lastLogin,
  versionApp,
}) => (
  <Paper sx={{ p: 2, fontSize: 13, color: 'grey.600' }}>
    <Grid
      alignItems="center"
      container
      spacing={2}
    >
      <Grid
        item
        md="auto"
        xs="auto"
      >
        <Avatar />
      </Grid>
      <Grid
        item
        md={2.5}
        xs={9}
      >
        <Typography
          sx={{ display: { xs: 'block', md: 'none' } }}
          variant="caption"
        >
          Nome
        </Typography>
        <Typography sx={{ fontWeight: 'bold' }}>{name}</Typography>
        <Typography>{cpf}</Typography>
      </Grid>
      <Grid
        item
        md={1.5}
        xs={6}
      >
        <Typography
          sx={{ display: { xs: 'block', md: 'none' } }}
          variant="caption"
        >
          Última atividade
        </Typography>
        {formatDate(lastLogin) ?? '-'}
      </Grid>
      <Grid
        item
        md={1}
        xs={6}
      >
        <Typography
          sx={{ display: { xs: 'block', md: 'none' } }}
          variant="caption"
        >
          Versão do App
        </Typography>
        {versionApp}
      </Grid>
      <Grid
        item
        md={3.5}
        xs={6}
      >
        <Typography
          sx={{ display: { xs: 'block', md: 'none' } }}
          variant="caption"
        >
          Cadastro
        </Typography>
        {userNameCognito}
      </Grid>
      <Grid
        item
        md
        xs={6}
      >
        <Typography
          sx={{ display: { xs: 'block', md: 'none' } }}
          variant="caption"
        >
          Convite
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          spacing={1}
        >
          <Typography
            sx={{
              color: invitation ? 'success.main' : 'warning.main',
              fontWeight: 'bold',
            }}
          >
            {!invitation ? 'Pendente' : 'Ativo'}
          </Typography>
          {!invitation && (
            <Tooltip title="Usuário cadastrado na base dados porém nunca fez onboarding no App">
              <InfoOutlined sx={{ fontSize: 15 }} />
            </Tooltip>
          )}
        </Stack>
      </Grid>
      <Grid
        alignSelf="center"
        item
        md="auto"
        sx={{ textAlign: 'right' }}
        xs={12}
      >
        <IconButton
          component={Link}
          to={`./${cpf}/detail`}
        >
          <ArrowForward />
        </IconButton>
      </Grid>
    </Grid>
  </Paper>
);

const UserList: React.FC = () => {
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { searchForm } = useOutletContext<IUserLayoutContext>();

  const { type, context } = useParams<{
    type: 'supervisor' | 'provider';
    context: 'base' | 'active';
  }>();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [meta, setMeta] = useState<IPagination<IUser>['meta']>({
    limit: 0,
    page: 1,
    pages: 0,
    records: 0,
  });

  const page = searchParams.get('page');

  const fetchUsers = useCallback(async () => {
    let centers = searchParams.getAll('centers') ?? [];
    if (centers.length === 0) {
      centers = (auth.user?.centers ?? []).map(({ id }) => id);
    }
    const response = await resources.use('user').usersByCodCenter({
      ...(searchParams.has('page') ? { page: Number(page ?? 1) } : {}),
      ...(searchParams.has('query')
        ? { query: searchParams.get('query')! }
        : {}),
      activeInApp: context === 'active',
      codCenters: centers,
      typeUsers: [type!],
    });

    if (response.status === 'OK') {
      setUsers(response.payload?.data ?? []);
      setMeta({
        limit: response.payload?.meta.limit ?? 0,
        page: response.payload?.meta.page ?? 0,
        pages: response.payload?.meta.pages ?? 0,
        records: response.payload?.meta.records ?? 0,
      });
    }
  }, [searchParams, page, context, type, auth.user?.centers]);

  useEffect(() => {
    searchForm.enable();
    return () => {
      searchForm.disable();
    };
  }, [searchForm]);

  useEffect(() => {
    setLoading(true);
    fetchUsers().finally(() => {
      setLoading(false);
    });
  }, [fetchUsers]);

  return (
    <Stack
      direction="column"
      spacing={1}
    >
      <Box
        px={2}
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        <Header />
      </Box>
      <Loading loading={loading} />
      {users.map((user) => (
        <UserListItem
          key={user.id}
          {...user}
        />
      ))}
      <Collapse in={users.length === 0 && !loading}>
        <Paper
          sx={{ p: 2, fontSize: 14, color: 'grey.500', textAlign: 'center' }}
        >
          Nenhum resultado disponível
        </Paper>
      </Collapse>

      {meta.pages > 1 && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          pt={4}
        >
          <Pagination
            count={meta.pages}
            onChange={(_, p) => {
              if (p > 1) {
                searchParams.set('page', `${p}`);
              } else {
                searchParams.delete('page');
              }
              setSearchParams(searchParams);
            }}
            sx={{ justifySelf: 'center' }}
          />
        </Stack>
      )}
    </Stack>
  );
};
export default UserList;
