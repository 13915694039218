import { SxProps, Theme } from '@mui/material';

export const wrapperStyles: SxProps<Theme> = {
  bgcolor: 'grey.100',
  minHeight: '100vh',
  minWidth: '100vw',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const cardFormStyles: SxProps<Theme> = {
  width: { xs: '100%', sm: 450 },
  p: { xs: 4, md: 8 },
  my: 2,
};
