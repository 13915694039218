import { PropsWithChildren, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';

interface IContent {
  title: ReactNode;
  header?: ReactNode;
  backButton?: boolean | LinkProps['to'];
}

const Content: React.FC<PropsWithChildren<IContent>> = ({
  children,
  title,
  header,
  backButton = false,
}) => (
  <Box sx={{ p: { xs: 2, md: 6 }, pb: { xs: 9 } }}>
    <Stack
      direction="column"
      spacing={{ xs: 2, md: 5 }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        {!!backButton && (
          <IconButton
            component={Link}
            to={typeof backButton === 'boolean' ? './..' : backButton}
          >
            <ArrowBack />
          </IconButton>
        )}
        <Typography
          color="tertiary.main"
          sx={{
            fontSize: { xs: 15, md: 24 },
            fontWeight: 'normal',
            mb: 0,
            flex: 1,
          }}
          variant="h2"
        >
          {title}
        </Typography>
        {header}
      </Stack>
      <Box>{children}</Box>
    </Stack>
  </Box>
);
export default Content;
