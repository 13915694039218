import Http from '../services/http';
import { IPagination } from './pagination';
import { IResourceResponse } from './response';

export type IBaseContent = {
  id: string;
  lang: 'pt_br' | 'en' | 'es';
  publishDate: string;
  codeCenter: string[];
  tags: string[];
};
interface IYoutubeContent extends IBaseContent {
  type: 'youtube';
  youtube: {
    title: string;
    videoUrl: string;
  };
}

interface IImageContent extends IBaseContent {
  type: 'image';
  image: {
    title: string;
    imageUrl: string;
  };
}

interface IFeedContent extends IBaseContent {
  type: 'feed';
  feed: {
    title: string;
    // subtitle: string;
    imageUrl: string;
    content: string;
  };
}

export type IContent = IFeedContent | IImageContent | IYoutubeContent;

export type ICreateContent = Omit<IContent, 'id'>;
export type IUpdateContent = Partial<ICreateContent>;

export interface IGetAllQueryParams {
  tags: string[];
  startPublishDate: string;
  page?: number;
}

export interface IContentResources {
  create(payload: ICreateContent): Promise<IResourceResponse<IContent>>;
  update(
    id: string,
    payload: IUpdateContent
  ): Promise<IResourceResponse<IContent>>;
  getById(id: string): Promise<IResourceResponse<IContent>>;
  deleteById(id: string): Promise<IResourceResponse<any>>;
  getAll(
    queryParams?: Partial<IGetAllQueryParams> | null
  ): Promise<IResourceResponse<IPagination<IContent>>>;
  uploadImage(file: File): Promise<IResourceResponse<string | undefined>>;
  getTags(): Promise<IResourceResponse<string[]>>;
}

const content: IContentResources = {
  async create(payload) {
    const { status, data } = await Http.post(
      `${process.env.REACT_APP_API_CONTENT_URL}/contentCenter`,
      payload
    );
    if (status === 201) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async update(id: string, payload: IUpdateContent) {
    const { status, data } = await Http.put(
      `${process.env.REACT_APP_API_CONTENT_URL}/contentCenter/${id}`,
      payload
    );
    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getById(id: string) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_CONTENT_URL}/contentCenter/${id}`
    );
    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async deleteById(id: string) {
    const { status, data } = await Http.delete(
      `${process.env.REACT_APP_API_CONTENT_URL}/contentCenter/${id}`
    );
    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getAll(queryParams) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_CONTENT_URL}/contentCenter`,
      { params: { ...queryParams, page: queryParams?.page ?? 1 } }
    );
    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async uploadImage(file: File) {
    const formData = new FormData();
    formData.append('image', file);
    const { status, data } = await Http.post(
      `${process.env.REACT_APP_API_CONTENT_URL}/contentCenter/uploadImage`,
      formData
    );

    if ([200, 201].includes(status)) {
      return {
        status: 'OK',
        payload: data.url,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getTags() {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_CONTENT_URL}/contentCenter/tags`
    );
    if ([200].includes(status)) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
};

export default content;
