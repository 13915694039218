import React, { useMemo, useRef, useState } from 'react';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';

import { Search } from '@mui/icons-material';
import { Grow, IconButton, InputBase, Paper, Stack } from '@mui/material';

import UserDashboardNavbarFilter from '../../../../components/filters/user-dashboard-navbar-filter';
import Content from '../../../../components/layouts/content';
import { Slot } from '../../../../contexts/slot';
import { getUserDescription } from '../../helpers/description';

export interface IUserLayoutContext {
  searchForm: {
    enable(): void;
    disable(): void;
  };
}

const UserLayout: React.FC = () => {
  const { type, context } = useParams<{ type: string; context: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [enableSearchForm, setEnableSearchForm] = useState(false);
  const inputRef = useRef<any>();

  const userTypeDescription = useMemo(() => getUserDescription(type!), [type]);
  const outletContext = useMemo<IUserLayoutContext>(
    () => ({
      searchForm: {
        enable() {
          setEnableSearchForm(true);
        },
        disable() {
          setEnableSearchForm(false);
        },
      },
    }),
    []
  );
  return (
    <Content
      backButton={context ? `../${type}` : '../'}
      header={
        <Grow
          in={enableSearchForm}
          unmountOnExit
        >
          <Paper
            component={Stack}
            direction="row"
            elevation={0}
            sx={() => ({
              pl: 2,
              pr: 0.3,
              py: 0.3,
              width: 1 / 3,
              borderRadius: 15,
              bgcolor: 'grey.100',
            })}
          >
            <InputBase
              defaultValue={searchParams.get('query') ?? ''}
              inputRef={inputRef}
              placeholder="Buscar por nome ou CPF..."
              sx={{ flex: 1, fontSize: 14 }}
            />
            <IconButton
              color="primary"
              onClick={() => {
                const { value } = inputRef.current;
                if (value !== '') {
                  searchParams.set('query', value ?? '');
                } else {
                  searchParams.delete('query');
                }
                setSearchParams(searchParams);
              }}
              size="small"
            >
              <Search />
            </IconButton>
          </Paper>
        </Grow>
      }
      title={userTypeDescription}
    >
      <Slot name="navbar">
        <UserDashboardNavbarFilter />
      </Slot>
      <Outlet context={outletContext} />
    </Content>
  );
};

export default UserLayout;
