import React, {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';

import { AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';

import resources from '../../resources';
import Http from '../../services/http';
import { useDrawer } from '../drawer';

type ISetLastUserTrigger = 'add' | 'remove' | 'change';

interface IAuthLoginResponse {
  authenticated: boolean;
  code:
    | 'USER_OK'
    | 'USER_BLOCKED'
    | 'USER_PASS_INVALID'
    | 'USER_INVALID_PERMISSION';
}

interface IAuthProviderProps {
  loginUrl: string;
  baseUrl: string;
}

interface IAuthUserInfo {
  name: string;
  roles: string[];
  identity: string;
  centers: {
    id: string;
    name: string;
  }[];
}

interface IAuthContextProperties {
  authenticated: boolean;
  user: IAuthUserInfo | null;
  lastUserLoggedIn: ILastUserLoggedIn | null;
  lastUsersLoggedIn: ILastUserLoggedIn[];
  setLastUserIn(
    userLogged: ILastUserLoggedIn,
    trigger: ISetLastUserTrigger
  ): void;
  login(
    username: string,
    password: string,
    remember: boolean
  ): Promise<IAuthLoginResponse>;
  logout(): Promise<any>;
  getInitialRoute(): string;
  config: IAuthProviderProps;
}

interface ILastUserLoggedIn {
  name: string;
  username: string;
  date: Date;
}

const STORAGE_USER_INFO = 'user_info';
const STORAGE_CURRENT_LAST_USER = 'current_user_last';
const STORAGE_USERS_LAST_LOGGED = 'user_lasts_logged';

const AuthContext = createContext<IAuthContextProperties>(
  {} as IAuthContextProperties
);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('auth context required');
  return context;
};

const AuthProvider: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<IAuthProviderProps>>
> = ({ children, loginUrl = '/auth/login', baseUrl = '/' }) => {
  const drawer = useDrawer();
  const [cookies, setCookie, removeCookie] = useCookies(['user_session']);

  const [authenticated, setAuthenticated] = useState<boolean>(
    () => !!cookies.user_session
  );

  const [user, setUser] = useState<IAuthUserInfo | null>(() => {
    try {
      const userInfo = localStorage.getItem(STORAGE_USER_INFO);
      if (!userInfo || !cookies.user_session) return null;

      return JSON.parse(userInfo);
    } catch {
      return null;
    }
  });

  const [lastUsersLoggedIn, setLastUsersLoggedIn] = useState<
    ILastUserLoggedIn[]
  >(() => {
    try {
      const userInfo = localStorage.getItem(STORAGE_USERS_LAST_LOGGED);

      if (!userInfo) return [];

      return JSON.parse(userInfo);
    } catch {
      return [];
    }
  });

  const [lastUserLoggedIn, setLastUserLoggedIn] =
    useState<ILastUserLoggedIn | null>(() => {
      try {
        const userInfo = localStorage.getItem(STORAGE_CURRENT_LAST_USER);

        if (!userInfo) return null;

        return JSON.parse(userInfo);
      } catch {
        return [];
      }
    });

  const setLastUser = useCallback(
    (userLastInfo: ILastUserLoggedIn, trigger: ISetLastUserTrigger) => {
      try {
        if (trigger === 'add') {
          setLastUserLoggedIn(userLastInfo);
          localStorage.setItem(
            STORAGE_CURRENT_LAST_USER,
            JSON.stringify(userLastInfo)
          );

          setLastUsersLoggedIn((oldValue) => {
            const newValue = [
              ...oldValue.filter((o) => o.username !== userLastInfo.username),
              userLastInfo,
            ];
            localStorage.setItem(
              STORAGE_USERS_LAST_LOGGED,
              JSON.stringify(newValue)
            );
            return newValue;
          });
        } else {
          setLastUserLoggedIn((old) => {
            if (old?.username === userLastInfo.username) {
              localStorage.removeItem(STORAGE_CURRENT_LAST_USER);
              return null;
            }
            return old;
          });

          if (trigger === 'remove') {
            setLastUsersLoggedIn((oldValue) => {
              const newValue = [
                ...oldValue.filter((o) => o.username !== userLastInfo.username),
              ];
              localStorage.setItem(
                STORAGE_USERS_LAST_LOGGED,
                JSON.stringify(newValue)
              );
              return newValue;
            });
          }
        }
      } catch {
        setLastUsersLoggedIn([]);
        localStorage.removeItem(STORAGE_USERS_LAST_LOGGED);
      }
    },
    []
  );

  const setUserInfo = useCallback((userInfo: IAuthUserInfo | null) => {
    try {
      if (userInfo) {
        setUser(userInfo);
        localStorage.setItem(STORAGE_USER_INFO, JSON.stringify(userInfo));
      } else {
        setUser(null);
        localStorage.removeItem(STORAGE_USER_INFO);
      }
    } catch {
      setUser(null);
      localStorage.removeItem(STORAGE_USER_INFO);
    }
  }, []);

  const loginHandler = useCallback(
    async (
      username: string,
      password: string,
      remember = false
    ): Promise<IAuthLoginResponse> => {
      const response = await resources.use('user').auth({
        username,
        password,
      });

      if (response.status === 'OK' && response.payload?.token) {
        const { exp, permissions }: any = jwtDecode(response.payload.token);
        if (
          permissions.some(
            (permission: any) => permission.group === 'DASHBOARD'
          )
        ) {
          setCookie('user_session', response.payload.token, {
            path: '/',
            expires: new Date(exp * 1000),
          });
          setUserInfo(response.payload?.user);

          if (remember) {
            setLastUser(
              {
                name: response.payload.user.name,
                username,
                date: new Date(),
              },
              'add'
            );
          } else {
            setLastUser(
              {
                name: response.payload.user.name,
                username,
                date: new Date(),
              },
              'remove'
            );
          }

          setAuthenticated(true);
          return {
            authenticated: true,
            code: 'USER_OK',
          };
        }

        setUserInfo(null);
        return {
          authenticated: false,
          code: 'USER_INVALID_PERMISSION',
        };
      }

      setAuthenticated(false);
      return {
        authenticated: false,
        code: 'USER_PASS_INVALID',
      };
    },
    [setCookie, setLastUser, setUserInfo]
  );
  const logoutHandler = useCallback(async () => {
    removeCookie('user_session', {
      path: '/',
    });
    localStorage.removeItem(STORAGE_USER_INFO);
    setUser(null);
    setAuthenticated(false);
    drawer.closeAll();
  }, [removeCookie, setAuthenticated, drawer]);

  const getInitialRouteHandler = useCallback(() => baseUrl, [baseUrl]);

  const props = useMemo(
    () => ({
      authenticated,
      user,
      login: loginHandler,
      logout: logoutHandler,
      lastUserLoggedIn,
      lastUsersLoggedIn,
      setLastUserIn: setLastUser,
      getInitialRoute: getInitialRouteHandler,
      config: { loginUrl, baseUrl },
    }),
    [
      authenticated,
      baseUrl,
      getInitialRouteHandler,
      lastUserLoggedIn,
      lastUsersLoggedIn,
      loginHandler,
      loginUrl,
      logoutHandler,
      setLastUser,
      user,
    ]
  );

  useLayoutEffect(() => {
    if (cookies.user_session) {
      Http.defaults.headers.common.Authorization = `Basic ${cookies.user_session}`;
    }
    Http.interceptors.response.use((response: AxiosResponse) => {
      if (response.status === 401) {
        logoutHandler();
      }
      return response;
    });
  }, [logoutHandler, cookies.user_session]);

  return <AuthContext.Provider value={props}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
