import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Collapse, Grow, Paper, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';

import resources from '../../../../resources';
import ConfirmPasswordRecoveryForm, {
  INewPasswordPayload,
} from './confirm-password-recovery-form';
import PasswordRecoveryForm, {
  IRememberPasswordPayload,
} from './password-recovery-form';
import { cardFormStyles, wrapperStyles } from './styles';

interface IRememberPassword {
  logoUrl: string;
}

const RememberPassword: React.FC<IRememberPassword> = ({ logoUrl }) => {
  const [sendRequestPasswordRecovery, setSendRequestPasswordRecovery] =
    useState(false);
  const snackbar = useSnackbar();
  const [username, setUsername] = useState<string>();
  const navigate = useNavigate();

  const requestPasswordRecovery = useCallback(
    async (values: IRememberPasswordPayload) => {
      const response = await resources.use('user').requestPasswordRecovery({
        username: values.username,
      });

      if (response.status === 'OK') {
        snackbar.enqueueSnackbar(
          response.message ?? 'Código enviado com sucesso',
          {
            variant: 'success',
          }
        );
        setSendRequestPasswordRecovery(true);
        setUsername(values.username);
      } else {
        snackbar.enqueueSnackbar(
          response.message ?? 'Ocorreu um problema ao tentar recuperar a senha',
          {
            variant: 'error',
          }
        );
        setSendRequestPasswordRecovery(false);
        setUsername(undefined);
      }
    },
    [snackbar]
  );

  const confirmPasswordRecovery = useCallback(
    async ({ confirmationCode, password }: INewPasswordPayload) => {
      if (!username) return;

      const response = await resources.use('user').confirmNewPassword({
        username,
        confirmationCode,
        password,
      });
      if (response.status === 'OK') {
        snackbar.enqueueSnackbar(
          response.message ?? 'Nova senha cadastrada com sucesso',
          {
            variant: 'success',
          }
        );
        navigate('../login');
      } else {
        snackbar.enqueueSnackbar(
          response.message ?? 'Ocorreu um problema ao tentar recuperar a senha',
          {
            variant: 'error',
          }
        );
      }
    },
    [snackbar, navigate, username]
  );

  return (
    <Box sx={wrapperStyles}>
      <Stack spacing={3}>
        <Grow in>
          <Paper
            elevation={3}
            sx={cardFormStyles}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <img
                alt="Piracanjuba Pró-Campo"
                height={90}
                src={logoUrl}
              />
            </Box>
            <Collapse
              in={!sendRequestPasswordRecovery}
              unmountOnExit
            >
              <PasswordRecoveryForm onSuccess={requestPasswordRecovery} />
            </Collapse>
            <Collapse
              in={sendRequestPasswordRecovery}
              unmountOnExit
            >
              <ConfirmPasswordRecoveryForm
                onSuccess={confirmPasswordRecovery}
              />
            </Collapse>
          </Paper>
        </Grow>
      </Stack>
    </Box>
  );
};

export default RememberPassword;
