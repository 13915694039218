import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ReportList from './elements/report-list';

const Report: React.FC = () => (
  <Routes>
    <Route
      element={<ReportList />}
      index
    />
  </Routes>
);

export default Report;
