import Http from '../services/http';
import { IResourceResponse } from './response';

interface ITermsOfUseReportDownloadPayload {
  fromDate: string | null;
  toDate: string | null;
  type: Array<'termsOfUse' | 'privacyPolicy' | 'producerManual'>;
  codCenters: string[] | null;
}

export interface IUtilResources {
  termsOfUseReportDownload({
    fromDate,
    toDate,
    codCenters,
    type,
  }: Partial<ITermsOfUseReportDownloadPayload>): Promise<
    IResourceResponse<any>
  >;
}

const util: IUtilResources = {
  async termsOfUseReportDownload({ fromDate, toDate, codCenters, type }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_UTIL_URL}/reports/terms`,
      {
        responseType: 'blob',
        params: {
          type: type?.join(','),
          fromDate,
          toDate,
          codCenter: codCenters?.join(','),
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
};

export default util;
