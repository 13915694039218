import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { format, subMonths } from 'date-fns';
import { sumBy } from 'lodash';

import UserDashboardNavbarFilter from '../../../../components/filters/user-dashboard-navbar-filter';
import Content from '../../../../components/layouts/content';
import { useAuth } from '../../../../contexts/auth';
import { Slot } from '../../../../contexts/slot';
import resources from '../../../../resources';
import { ITypesTotals } from '../../../../resources/user';
import UserCardReport from '../../components/user-card-report';

const UserIndex: React.FC = () => {
  const auth = useAuth();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const [userTypesTotals, setUserTypesTotals] = useState<ITypesTotals>();

  const centersDescription = useMemo(() => {
    if (
      !searchParams.has('centers') ||
      searchParams.getAll('centers').length === 0
    )
      return '';

    const centers = searchParams.getAll('centers');

    return `em ${centers.slice(0, 3).join(', ')}${
      centers.length > 3 ? `, +${centers.length - 3}` : ''
    }`;
  }, [searchParams]);

  const generateLink = (link: string) => {
    if (Array.from(searchParams.keys()).length > 0) {
      return `${link}?${searchParams.toString()}`;
    }
    return link;
  };

  const getParams = (query: {
    fromDate: Date | string | null;
    toDate: Date | string | null;
    centers: string[] | null;
  }) => ({
    fromDate: query.fromDate
      ? format(new Date(query.fromDate), 'yyyy-MM-dd')
      : format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    toDate: query.toDate
      ? format(new Date(query.toDate), 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd'),
    codCenters: query.centers ?? [],
  });

  const fetchUserTypesTotals = useCallback(async () => {
    let centers = searchParams.getAll('centers') ?? [];
    if (centers.length === 0) {
      centers = auth.user?.centers?.map((c) => c.id) ?? [];
    }

    const params = getParams({
      centers,
      fromDate: searchParams.get('fromDate'),
      toDate: searchParams.get('toDate'),
    });
    const response = await resources.use('user').userTypesTotals(params);

    if (response.status === 'OK') {
      setUserTypesTotals(response.payload);
    }
  }, [auth.user?.centers, searchParams]);

  const providerTotals = useMemo(
    () =>
      sumBy(
        (userTypesTotals?.totalInBase ?? []).filter(
          (t) => t.typeUser === 'provider'
        ),
        (t) => +t.activeInBase
      ),
    [userTypesTotals?.totalInBase]
  );

  const supervisorTotals = useMemo(
    () =>
      sumBy(
        (userTypesTotals?.totalInBase ?? []).filter((t) =>
          ['supervisor', 'purchasingSupervisor', 'qualitySupervisor'].includes(
            t.typeUser
          )
        ),
        (t) => +t.activeInBase
      ),
    [userTypesTotals?.totalInBase]
  );

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchUserTypesTotals()]).finally(() => {
      setLoading(false);
    });
  }, [fetchUserTypesTotals]);

  return (
    <Content title="Central de Usuários">
      <Slot name="navbar">
        <UserDashboardNavbarFilter visiblePeriodFilter={false} />
      </Slot>
      <Grid container>
        <Grid
          item
          md={6}
        >
          <UserCardReport
            description={`Total de Fornecedores ${centersDescription}`}
            label="Fornecedores"
            loading={loading}
            to={generateLink('./provider')}
            value={providerTotals}
          />
        </Grid>
        <Grid
          item
          md={6}
        >
          <UserCardReport
            description={`Total de Supervisores ${centersDescription}`}
            label="Supervisores"
            loading={loading}
            to={generateLink('./supervisor')}
            value={supervisorTotals}
          />
        </Grid>
      </Grid>
    </Content>
  );
};

export default UserIndex;
