import { alpha, createTheme, lighten } from '@mui/material';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#57844b',
      light: '#57844b',
      dark: '#44673b',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f36b20',
      light: '#fca428',
      dark: '#f36b20',
      contrastText: '#ffffff',
    },
    tertiary: {
      main: '#3b5e32',
      light: '#3b5e32',
      dark: '#3b5e32',
      contrastText: '#ffffff',
    },
    error: {
      main: '#f24343',
      light: '#ff6a6a',
      dark: '#f24343',
    },
  },
});

export const makeBackground = (
  color1: string,
  color2: string,
  deg = 90
): string => `linear-gradient(${deg}deg, ${color1}, ${color2})`;

const theme = createTheme({
  palette: {
    primary: defaultTheme.palette.primary,
    secondary: defaultTheme.palette.secondary,
    tertiary: defaultTheme.palette.tertiary,
  },
  shape: {
    borderRadius: 3,
  },
  shadows: {
    ...defaultTheme.shadows,
    1: '0 2px 6px 0 rgba(0, 0, 0, 0.04)',
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        sizeSmall: {
          padding: 4,
          '& .MuiSwitch-track': {
            borderRadius: 22 / 2,
            '&:before, &:after': {
              content: '""',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              width: 12,
              height: 12,
            },
          },
          '& .MuiSwitch-thumb': {
            width: 12,
            height: 12,
            margin: 2,
          },
        },
        sizeMedium: {
          padding: 8,
          '& .MuiSwitch-track': {
            borderRadius: 22 / 2,
            '&:before, &:after': {
              content: '""',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              width: 16,
              height: 16,
            },
          },
          '& .MuiSwitch-thumb': {
            width: 16,
            height: 16,
            margin: 2,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 4,
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'inherit',
          color: defaultTheme.palette.grey[500],
          fontSize: 12,
          '&.Mui-selected': {
            color: defaultTheme.palette.grey[700],
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 12,
          color: 'grey.600',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          mt: 1,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: defaultTheme.shadows[1],
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: alpha(defaultTheme.palette.secondary.main, 0.8),
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            py: 1,
            color: defaultTheme.palette.secondary.dark,
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableTouchRipple: true,
      },
      styleOverrides: {
        containedPrimary: {
          background: makeBackground(
            defaultTheme.palette.secondary.light,
            defaultTheme.palette.secondary.dark
          ),
          '&:hover': {
            background: makeBackground(
              lighten(defaultTheme.palette.secondary.light, 0.1),
              lighten(defaultTheme.palette.secondary.dark, 0.1)
            ),
          },
        },
        containedError: {
          background: makeBackground(
            defaultTheme.palette.error.light,
            defaultTheme.palette.error.dark
          ),
          '&:hover': {
            background: makeBackground(
              lighten(defaultTheme.palette.error.light, 0.1),
              lighten(defaultTheme.palette.error.dark, 0.1)
            ),
          },
        },
        textTertiary: {
          color: defaultTheme.palette.grey[700],
          '& .MuiButton-startIcon': {
            '& .MuiSvgIcon-root': {
              color: defaultTheme.palette.tertiary.main,
            },
          },
        },
        root: {
          fontSize: 14,
          textTransform: 'inherit',
          borderRadius: 20,
          '& .MuiButton-startIcon': {
            '& .MuiSvgIcon-root': {
              fontSize: 16,
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child .MuiTableCell-root': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 1,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 1,
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        dividers: true,
        root: {
          p: 3,
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: 3,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
      },
    },
    MuiInputAdornment: {
      defaultProps: {
        color: defaultTheme.palette.grey[300],
      },
      styleOverrides: {
        root: {
          '&.MuiInputAdornment-positionStart .MuiSvgIcon-root': {
            color: defaultTheme.palette.grey[300],
            fontSize: 20,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            backgroundColor: 'transparent!important',
          },
        },
      },
      defaultProps: {
        fullWidth: true,
        variant: 'filled',
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
        caption: {
          color: defaultTheme.palette.grey[500],
        },
        subtitle1: {
          color: defaultTheme.palette.grey[600],
          textTransform: 'uppercase',
          fontWeight: 'bold',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-end',
        },
      },
    },
  },
});

export default theme;
