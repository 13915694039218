import React from 'react';

import { Button, Paper, Stack } from '@mui/material';

export interface SwitchOptionItem<T> {
  label: string;
  value: T;
}

interface ISwitch<T = string | number> {
  currentActive: string | number | null;
  onChange: (value: SwitchOptionItem<T>['value']) => void;
  disabled?: boolean;
  options: Array<SwitchOptionItem<T>>;
}

const Switch: React.FC<ISwitch> = ({
  currentActive,
  onChange,
  options,
  disabled = false,
}) => (
  <Paper
    component={Stack}
    direction="row"
    sx={{ borderRadius: 20, bgcolor: 'grey.100' }}
  >
    {options.map(({ label, value }, key) => (
      <Button
        color={value !== currentActive ? 'secondary' : undefined}
        disabled={disabled}
        disableElevation
        disableRipple={value !== currentActive}
        disableTouchRipple={value !== currentActive}
        key={key}
        onClick={() => {
          onChange(value);
        }}
        sx={{ width: 1 / 2, textTransform: 'uppercase' }}
        value={value}
        variant={value === currentActive ? 'contained' : 'text'}
      >
        {label}
      </Button>
    ))}
  </Paper>
);

export default Switch;
