import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { UploadFile } from '@mui/icons-material';
import {
  createTheme,
  FormHelperText,
  Theme,
  ThemeProvider,
} from '@mui/material';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { convertToRaw, EditorState } from 'draft-js';
import { get } from 'lodash';
import MUIRichTextEditor, {
  TAsyncAtomicBlockResponse,
  TCustomControl,
  TMUIRichTextEditorProps,
  TMUIRichTextEditorRef,
  TMUIRichTextEditorStyles,
  TToolbarControl,
} from 'mui-rte';

import UploadImagePopover from './upload-image-popover';

export type TextProps = TMUIRichTextEditorProps & {
  name: string;
  onUpload?(file: File): Promise<string | undefined>;
  onSetValue?(event: any, value: { html: string; raw: string }): any;
};

export const defaultTheme: Theme = createTheme();

const muiRteTheme: TMUIRichTextEditorStyles = {
  overrides: {
    MUIRichTextEditor: {
      editor: {
        minHeight: '100px',
        overflow: 'auto',
      },
    },
  },
};

Object.assign(defaultTheme, muiRteTheme);

const editorControls: TToolbarControl[] = [
  'title',
  'bold',
  'italic',
  'underline',
  'link',
  'numberList',
  'bulletList',
  // 'quote',
  // 'code',
  // 'clear',
  // 'upload-image',
  // 'strikethrough',
  // 'highlight',
];

type CustomControlSchemaOptions = {
  onClick(...args: any[]): void;
};

type CustomControlSchema = (
  options: CustomControlSchemaOptions
) => TCustomControl[];
const toHtml = convertToHTML({
  entityToHTML: (entity, originalText) => {
    if (entity.type === 'LINK') {
      return <a href={entity.data.url}>{originalText}</a>;
    }
    return originalText;
  },
  blockToHTML(block) {
    if (block.type === 'unstyled' && (block.text === ' ' || block.text === ''))
      return <br />;
    return undefined;
  },
});

const customControls: CustomControlSchema = ({ onClick }) => [
  {
    name: 'upload-image',
    icon: <UploadFile />,
    type: 'callback',
    onClick: (...args: any[]) => {
      onClick(...args);
    },
  },
];

const EditorElement = React.forwardRef<any, TextProps>(
  ({ name, label, onUpload, onSetValue, ...props }, ref) => {
    const muiRichTextRef = useRef<TMUIRichTextEditorRef>(null);
    const { control, setValue, formState, getValues } = useFormContext();
    const [uploadImageAnchor, setUploadImageAnchor] =
      useState<HTMLElement | null>(null);

    const handleFileUpload = useCallback(
      async (file: File) => {
        const upload = async (): Promise<TAsyncAtomicBlockResponse> => {
          if (onUpload) {
            const url = await onUpload(file);

            if (!url) return { data: null };

            return {
              data: {
                src: url,
                url,
                width: 300,
                height: 200,
                alignment: 'left', // or "center", "right"
                type: 'image', // or "video"
              },
            } as TAsyncAtomicBlockResponse;
          }
          return { data: null };
        };

        muiRichTextRef.current?.insertAtomicBlockAsync(
          'IMAGE',
          upload(),
          'Carregando...'
        );
      },
      [onUpload]
    );

    const error = get(formState.errors, name);
    const content = useMemo(() => {
      const contentHTML = convertFromHTML({
        htmlToEntity: (nodeName, node, createEntity) => {
          if (nodeName === 'a') {
            return createEntity('LINK', 'MUTABLE', { url: node.href });
          }
          return undefined;
        },
      })(getValues(name) ?? '');

      const state = contentHTML;

      return {
        state: EditorState.createWithContent(state),
        data: JSON.stringify(convertToRaw(state)),
      };
    }, [getValues, name]);

    const customControlSchema = useMemo(
      () =>
        customControls({
          onClick(_editorState, _name, anchor) {
            if (_name === 'upload-image') {
              setUploadImageAnchor(anchor);
            }
          },
        }),
      []
    );

    useImperativeHandle(ref, () => muiRichTextRef, [muiRichTextRef]);

    return (
      <>
        <UploadImagePopover
          anchor={uploadImageAnchor}
          onSubmit={(data, insert) => {
            if (insert && data.file) {
              handleFileUpload(data.file);
            }
            setUploadImageAnchor(null);
          }}
        />
        <ThemeProvider theme={defaultTheme}>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <>
                <MUIRichTextEditor
                  {...props}
                  controls={editorControls}
                  customControls={customControlSchema}
                  label={label}
                  onBlur={field.onBlur}
                  onChange={(event: EditorState) => {
                    const html = toHtml(event.getCurrentContent());
                    if (event.getCurrentContent().getPlainText() !== '') {
                      if (onSetValue) {
                        setValue(
                          name,
                          onSetValue(event, {
                            html,
                            raw: content.state
                              .getCurrentContent()
                              .getPlainText(),
                          })
                        );
                      } else {
                        setValue(name, html);
                      }
                    } else {
                      setValue(name, null);
                    }
                  }}
                  ref={muiRichTextRef}
                  value={content.data}
                />
                {!!error && (
                  <FormHelperText
                    sx={{ color: (theme) => theme.palette.error.main }}
                  >
                    {String(error.message)}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </ThemeProvider>
      </>
    );
  }
);

export default EditorElement;
