import { CheckCircle } from '@mui/icons-material';
import { Stack, StackProps, Typography } from '@mui/material';

import {
  IPasswordPolicy,
  validationPasswordPolicy,
} from '../../libraries/validator/validate-password-policy';

interface ICheckPasswordPolicy {
  value: string;
  rules: IPasswordPolicy;
  sx?: StackProps['sx'];
}

interface IRule {
  rule: string;
  checked: boolean;
}

const Rule: React.FC<IRule> = ({ checked, rule }) => (
  <Stack
    alignItems="center"
    direction="row"
    spacing={1}
  >
    <CheckCircle
      sx={(theme) => ({
        color: !checked ? theme.palette.grey[300] : theme.palette.success.main,
        transition: theme.transitions.create('color'),
      })}
    />
    <Typography
      sx={(theme) => ({
        color: !checked ? theme.palette.grey[600] : theme.palette.success.main,
        fontWeight: !checked ? 'inherit' : 'bold',
        transition: theme.transitions.create(['color']),
      })}
      variant="caption"
    >
      {rule}
    </Typography>
  </Stack>
);

const CheckPasswordPolicy: React.FC<ICheckPasswordPolicy> = ({
  rules,
  value,
  sx,
}) => {
  const validation = validationPasswordPolicy(value, rules);
  return (
    <Stack
      spacing={1}
      sx={sx}
    >
      {rules.minUppercaseCharacters > 0 && (
        <Rule
          checked={validation.minUppercaseCharacters}
          rule={`Pelo menos ${rules.minUppercaseCharacters} caractere maiúsculo`}
        />
      )}
      {rules.minUppercaseCharacters > 0 && (
        <Rule
          checked={validation.minLowercaseCharacters}
          rule={`Pelo menos ${rules.minLowercaseCharacters} caractere minúsculo`}
        />
      )}
      {rules.minSpecialCharacters > 0 && (
        <Rule
          checked={validation.minSpecialCharacters}
          rule={`Pelo menos ${rules.minSpecialCharacters} caractere especial`}
        />
      )}
      {rules.minLength > 0 && (
        <Rule
          checked={validation.minLength}
          rule={`Pelo menos ${rules.minLength} caracteres`}
        />
      )}
      {rules.maxLength > 0 && (
        <Rule
          checked={validation.maxLength}
          rule={`Máximo ${rules.maxLength} caracteres`}
        />
      )}
    </Stack>
  );
};

export default CheckPasswordPolicy;
