import React from 'react';
import { LinkProps, useNavigate } from 'react-router-dom';

import {
  Card,
  CardActionArea,
  Collapse,
  Grow,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

interface IUserCardReport {
  label: string;
  description: string;
  value: number;
  to: LinkProps['to'];
  loading?: boolean;
}

const UserCardReport: React.FC<IUserCardReport> = ({
  label,
  description,
  value,
  to,
  loading = false,
}) => {
  const navigate = useNavigate();
  return (
    <Card>
      <Stack
        alignItems="start"
        component={CardActionArea}
        onClick={() => {
          navigate(to);
        }}
        sx={{ p: 3, textAlign: 'left' }}
      >
        <Grow
          in={loading}
          unmountOnExit
        >
          <Skeleton
            animation="wave"
            sx={{ fontSize: 16, mb: 1 }}
            variant="text"
            width={100}
          />
        </Grow>
        <Collapse
          in={!loading}
          unmountOnExit
        >
          <Typography
            color="primary"
            sx={{ fontWeight: 'bold', fontSize: 16 }}
          >
            {label}
          </Typography>
        </Collapse>
        <Grow
          in={loading}
          unmountOnExit
        >
          <Skeleton
            animation="wave"
            sx={{ fontSize: 25, mb: 1 }}
            variant="text"
            width={30}
          />
        </Grow>
        <Collapse
          in={!loading}
          unmountOnExit
        >
          <Typography
            sx={{ fontSize: 25, color: 'grey.800', fontWeight: 'bold' }}
          >
            {value}
          </Typography>
        </Collapse>
        <Grow
          in={loading}
          unmountOnExit
        >
          <Skeleton
            animation="wave"
            sx={{ fontSize: 25 }}
            variant="text"
            width={150}
          />
        </Grow>
        <Collapse
          in={!loading}
          unmountOnExit
        >
          <Typography sx={{ fontSize: 14, color: 'grey.500' }}>
            {description}
          </Typography>
        </Collapse>
      </Stack>
    </Card>
  );
};

export default UserCardReport;
