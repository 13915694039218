/* eslint-disable no-underscore-dangle */
import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Collapse } from '@mui/material';

interface ISlot {
  name: string;
  component: ReactNode;
  visible: boolean;
}

interface ISlotContext {
  slots: ISlot[];
  setSlot(slot: ISlot): void;
  setVisible(name: ISlot['name']): void;
  getSlot(name: ISlot['name']): ISlot | undefined;
}

const SlotContext = createContext<ISlotContext | undefined>(undefined);

const useSlot = () => {
  const context = useContext(SlotContext);
  if (!context) throw new Error('required slot provider');
  return context;
};

export const Slot: React.FC<PropsWithChildren<{ name: ISlot['name'] }>> = ({
  name,
  children,
}) => {
  const ref = React.useRef(null);
  const ready = useRef(false);
  const slot = useSlot();

  useEffect(() => {
    if (!ready.current) {
      slot.setSlot({
        name,
        component: children,
        visible: true,
      });
      ready.current = true;
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (ref.current === null) {
        slot.setVisible(name);
      }
    };
  }, [slot, children, name]);

  return (
    <span
      ref={ref}
      style={{ display: 'none', width: 0, height: 0 }}
    />
  );
};

export const Outlet: React.FC<{ name: ISlot['name'] }> = ({ name }) => {
  const { slots } = useSlot();
  const [component, setComponent] = useState<ISlot>();

  useEffect(() => {
    const node = slots.find((s) => s.name === name);
    if (node) {
      setComponent(node);
    } else {
      setComponent(undefined);
    }
  }, [slots, name]);

  if (!component) return null;

  return (
    <Collapse
      in={component.visible}
      unmountOnExit
    >
      {component.component}
    </Collapse>
  );
};

const SlotProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [slots, setSlots] = useState<ISlot[]>([]);
  const contextProps = useMemo(
    () => ({
      slots,
      setSlot(slot: ISlot) {
        setSlots((old) => [...old.filter((o) => o.name !== slot.name), slot]);
      },
      getSlot(name: ISlot['name']) {
        return slots.find((s) => s.name === name);
      },
      setVisible(name: ISlot['name']) {
        setSlots((old) =>
          [...old].map((o) => ({
            ...o,
            visible: o.name !== name,
          }))
        );
      },
    }),
    [slots]
  );

  return (
    <SlotContext.Provider value={contextProps}>{children}</SlotContext.Provider>
  );
};

export default SlotProvider;
