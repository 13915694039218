import { Box, Paper, Typography, useTheme } from '@mui/material';
import {
  Brush,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { numberFormat } from '../../../helpers/number';

export interface IChartLineValues {
  max?: number;
  items: {
    name: string;
    value: number;
  }[];
}

interface IChartLine {
  values: IChartLineValues;
  valuesOptions?: Intl.NumberFormatOptions;
}

// const ShapeBarLabel = ({
//   x,
//   y,
//   fill,
//   height,
//   value,
//   numberFormatOptions,
// }: any) => {
//   const theme = useTheme();

//   return (
//     <text
//       dy={-6}
//       fill={fill}
//       fontSize={13}
//       height={height}
//       stroke={theme.palette.primary.main}
//       textAnchor="middle"
//       x={x}
//       y={y}
//     >
//       {numberFormat(value, numberFormatOptions)}
//     </text>
//   );
// };

const CustomTooltip = ({ active, payload, label, valuesOptions }: any) => {
  if (active && payload && payload.length) {
    return (
      <Paper
        elevation={3}
        sx={{ p: 1 }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
        <Typography sx={{ fontSize: 10 }}>
          {numberFormat(payload[0].value, {
            ...valuesOptions,
          })}
        </Typography>
      </Paper>
    );
  }

  return null;
};

const ChartLine: React.FC<IChartLine> = ({ values, valuesOptions }) => {
  const theme = useTheme();
  return (
    <Box
      height={320}
      width="100%"
    >
      <ResponsiveContainer
        minHeight="100%"
        width="100%"
      >
        <LineChart
          data={values?.items ?? []}
          height={300}
          margin={{ right: 40, left: 25, bottom: 0, top: 20 }}
          maxBarSize={100}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            axisType="xAxis"
            dataKey="name"
            fontSize={11}
            type="category"
          />
          <YAxis
            domain={values?.max ? [0, values?.max ?? 0] : undefined}
            fontSize={11}
            tickFormatter={(tick) => {
              if (!Number.isNaN(tick)) {
                if (tick >= 1000000) {
                  return `${numberFormat(tick, {
                    ...valuesOptions,
                    notation: 'compact',
                  })}`;
                }
                return numberFormat(tick / 1000000, valuesOptions);
              }
              return tick;
            }}
          />
          <Tooltip content={<CustomTooltip valuesOptions={valuesOptions} />} />
          {values?.items.length > 10 && (
            <Brush
              alwaysShowText={false}
              endIndex={10}
              startIndex={0}
              stroke={theme.palette.primary.main}
            />
          )}
          <Line
            activeDot={{ r: 8 }}
            dataKey="value"
            label={{
              value: 'value',
              offset: 10,
              fontSize: 11,
              position: 'top',

              fill: theme.palette.primary.main,
              formatter: (value: number) =>
                numberFormat(value, {
                  ...valuesOptions,
                  notation: 'compact',
                }),
            }}
            layout="horizontal"
            stroke={theme.palette.primary.light}
            strokeWidth={2}
            type="linear"
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ChartLine;
