import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import { LinkProps, NavLink } from 'react-router-dom';

import {
  Assessment,
  ExitToApp,
  Forum,
  Home,
  ManageAccounts,
  Notifications,
  PostAdd,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import packageJson from '../../../package.json';
import { useAuth } from '../../contexts/auth';
import {
  footerMenuLogoutStyles,
  menuItemStyles,
  wrapperStyles,
} from './styles';

interface IMenuItem {
  icon: ReactNode;
  label: ReactNode;
  to: LinkProps['to'];
}

const NavigationContext = createContext(null);

export const NavigationProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => (
  <NavigationContext.Provider value={null}>
    {children}
  </NavigationContext.Provider>
);

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) throw new Error('Navigation Provider required');
  return context;
};

const MenuItem: React.FC<IMenuItem> = ({ label, icon, to }) => {
  const theme = useTheme();
  const onlyMobile = useMediaQuery(theme.breakpoints.down('md'));

  const props = useMemo(() => {
    if (typeof to === 'string' && to.startsWith('http')) {
      return {
        component: 'a',
        href: to,
        target: '_blank',
      };
    }
    return {
      component: NavLink,
      end: !!(
        (typeof to === 'string' && to === '/') ||
        (typeof to === 'object' && to.pathname === '/')
      ),
      to,
    };
  }, [to]);

  if (onlyMobile)
    return (
      <Tooltip title={label}>
        <IconButton
          {...props}
          sx={{ color: 'white' }}
        >
          {icon}
        </IconButton>
      </Tooltip>
    );

  return (
    <Button
      {...props}
      disableElevation
      size="medium"
      startIcon={icon}
      sx={menuItemStyles}
      variant="contained"
    >
      {label}
    </Button>
  );
};

const Menu: React.FC = () => {
  const auth = useAuth();

  return (
    <Stack
      direction="row"
      sx={{ flexGrow: 0, width: { xs: 200, md: 295 } }}
    >
      <Paper
        square
        sx={wrapperStyles}
      >
        <Box
          sx={{
            position: { xs: 'relative', md: 'fixed' },
            width: { xs: '100vw', md: 295 },
            height: { md: '100vh' },
            overflowY: 'auto',
          }}
        >
          <Stack
            direction={{ xs: 'row', md: 'column' }}
            justifyContent={{ xs: 'space-around', md: 'inherit' }}
            spacing={3}
            sx={{
              px: { xs: 1, md: 3 },
              pt: { xs: 1, md: 3 },
              pb: { xs: 1, md: 22 },
              flexGrow: 1,
              flexShrink: 1,
              oveflow: { xs: 'hidden', md: 'inherit' },
            }}
          >
            <MenuItem
              icon={<Home />}
              label="Visão Geral"
              to="/"
            />
            <MenuItem
              icon={<Assessment />}
              label="Relatórios"
              to="/reports"
            />
            <MenuItem
              icon={<Notifications />}
              label="Central de notificações"
              to="/notifications"
            />
            <MenuItem
              icon={<PostAdd />}
              label="Central de conteúdos"
              to="/contents"
            />
            <MenuItem
              icon={<ManageAccounts />}
              label="Central de usuários"
              to="/users"
            />
            <MenuItem
              icon={<Forum />}
              label="Fale Conosco"
              to="/contact"
            />
          </Stack>
          <Button
            onClick={auth.logout}
            startIcon={<ExitToApp />}
            sx={footerMenuLogoutStyles}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ width: 1 }}
            >
              <span>Sair</span>
              <span>v{packageJson.version}</span>
            </Stack>
          </Button>
        </Box>
      </Paper>
    </Stack>
  );
};

export default Menu;
