import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ContentList from './elements/content-list';
import CreateContent from './elements/create';
import UpdateContent from './elements/update';

const Content: React.FC = () => (
  <Routes>
    <Route
      element={<ContentList />}
      index
    />
    <Route
      element={<CreateContent />}
      path="create"
    />
    <Route
      element={<UpdateContent />}
      path=":id"
    />
  </Routes>
);

export default Content;
