import { useCallback, useState } from 'react';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { Link } from 'react-router-dom';

import { Person } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Collapse,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';

import Validator, { resolveAdapter } from '../../../../libraries/validator';

interface IPasswordRecoveryForm {
  onSuccess(values: any): Promise<void>;
}

export interface IRememberPasswordPayload {
  username: string;
}

const rememberPasswordSchemaValidation = Validator.object().shape({
  username: Validator.string().nullable().required().label('Usuário'),
});

const PasswordRecoveryForm: React.FC<IPasswordRecoveryForm> = ({
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);

  const onSuccessHandler = useCallback(
    async (values: any) => {
      setLoading(true);
      await onSuccess(values);
      setLoading(false);
    },
    [onSuccess]
  );

  return (
    <FormContainer
      defaultValues={{ username: undefined }}
      onSuccess={onSuccessHandler}
      resolver={resolveAdapter(rememberPasswordSchemaValidation)}
    >
      <Stack spacing={3}>
        <Typography
          sx={{ textAlign: 'center' }}
          variant="caption"
        >
          Digite seu <strong>usuário</strong> para recuperar a senha
        </Typography>
        <Stack spacing={1}>
          <Stack spacing={2}>
            <TextFieldElement
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person color="inherit" />
                  </InputAdornment>
                ),
              }}
              label="usuário"
              name="username"
              required
            />
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <Collapse
            in={loading}
            sx={{ alignSelf: 'center' }}
          >
            <CircularProgress size={20} />
          </Collapse>
          <Collapse in={!loading}>
            <Button
              color="primary"
              disabled={loading}
              fullWidth
              sx={{ borderRadius: 20 }}
              type="submit"
              variant="contained"
            >
              RECUPERAR SENHA
            </Button>
          </Collapse>
          <Button
            color="primary"
            component={Link}
            sx={{ borderRadius: 20 }}
            to="/auth/login"
          >
            VOLTAR AO LOGIN
          </Button>
        </Stack>
      </Stack>
    </FormContainer>
  );
};

export default PasswordRecoveryForm;
