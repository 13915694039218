export const numberFormat = (
  value: number,
  valueFormatOption?: Intl.NumberFormatOptions
) => {
  if (Number.isNaN(value)) return null;
  return Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    ...valueFormatOption,
  }).format(value);
};
