import { CircularProgress, Collapse, Paper, PaperProps } from '@mui/material';

interface ILoading {
  loading: boolean;
  paperProps?: PaperProps;
}

const Loading: React.FC<ILoading> = ({ loading, paperProps }) => (
  <Collapse
    in={loading}
    unmountOnExit
  >
    <Paper
      {...paperProps}
      sx={{
        p: 4,
        display: 'flex',
        justifyContent: 'center',
        ...paperProps?.sx,
      }}
    >
      <CircularProgress />
    </Paper>
  </Collapse>
);
export default Loading;
