import { TestContext } from 'yup';

export interface IPasswordPolicy {
  minLength: number;
  maxLength: number;
  minUppercaseCharacters: number;
  minLowercaseCharacters: number;
  minSpecialCharacters: number;
  minNumericCharacters: number;
}
export const validationPasswordPolicy = (
  value: string | undefined,
  passwordPolicy: IPasswordPolicy
) => {
  const validation = {
    minLength: true,
    maxLength: true,
    minUppercaseCharacters: true,
    minLowercaseCharacters: true,
    minSpecialCharacters: true,
    minNumericCharacters: true,
  };

  if (!value) {
    return {
      minLength: false,
      maxLength: false,
      minUppercaseCharacters: false,
      minLowercaseCharacters: false,
      minSpecialCharacters: false,
      minNumericCharacters: false,
    };
  }

  if (!passwordPolicy) return validation;

  if (passwordPolicy.minLength > 0) {
    validation.minLength = false;
    if (value.length >= passwordPolicy.minLength) {
      validation.minLength = true;
    }
  }

  if (passwordPolicy.maxLength > 0) {
    validation.maxLength = false;
    if (value.length <= passwordPolicy.maxLength) {
      validation.maxLength = true;
    }
  }

  if (passwordPolicy.minLowercaseCharacters > 0) {
    validation.minLowercaseCharacters = false;
    const character = value.replace(/[^a-z]+/, '');
    if (character.length >= passwordPolicy.minLowercaseCharacters) {
      validation.minLowercaseCharacters = true;
    }
  }

  if (passwordPolicy.minUppercaseCharacters > 0) {
    validation.minUppercaseCharacters = false;
    const character = value.replace(/[^A-Z]+/, '');
    if (character.length >= passwordPolicy.minUppercaseCharacters) {
      validation.minUppercaseCharacters = true;
    }
  }

  if (passwordPolicy.minNumericCharacters > 0) {
    validation.minNumericCharacters = false;
    const character = value.replace(/[^0-9]+/, '');
    if (character.length >= passwordPolicy.minNumericCharacters) {
      validation.minNumericCharacters = true;
    }
  }

  if (passwordPolicy.minSpecialCharacters > 0) {
    validation.minSpecialCharacters = false;
    const character = value.replace(/[A-Za-z0-9]+/, '');
    if (character.length >= passwordPolicy.minSpecialCharacters) {
      validation.minSpecialCharacters = true;
    }
  }

  return validation;
};

const validatePasswordPolicy =
  (rules: IPasswordPolicy) =>
  async (value: string | null | undefined, test: TestContext) => {
    if (!value) return true;
    const validation = validationPasswordPolicy(value, rules);

    if (!validation.minLength) {
      return test.createError({
        message: `Mínimo ${rules.minLength} caracteres`,
      });
    }
    if (!validation.maxLength) {
      return test.createError({
        message: `Máximo ${rules.maxLength} caracteres`,
      });
    }
    if (!validation.minLowercaseCharacters) {
      return test.createError({
        message: `Minímo ${rules.minLowercaseCharacters} letras minúsculas`,
      });
    }
    if (!validation.minUppercaseCharacters) {
      return test.createError({
        message: `Minímo ${rules.minUppercaseCharacters} letras maiúsculas`,
      });
    }
    if (!validation.minNumericCharacters) {
      return test.createError({
        message: `Minímo ${rules.minNumericCharacters} números`,
      });
    }
    if (!validation.minSpecialCharacters) {
      return test.createError({
        message: `Minímo ${rules.minSpecialCharacters} caracteres especiais`,
      });
    }

    return true;
  };

export default validatePasswordPolicy;
