import React, { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  ArrowDropDown,
  BusinessCenter,
  CalendarMonth,
  FilterList,
  Flag,
  Refresh,
} from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { endOfMonth, format, parse, startOfMonth } from 'date-fns';
import { isEmpty } from 'lodash';
import {
  bindDialog,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import CenterFilter from '../../../../components/filters/center-filter';
import DatePeriodFilter, {
  ApplyValues,
  IDatePeriodFilter,
} from '../../../../components/filters/date-period-filter';
import SupervisorFilter from '../../../../components/filters/supervisor-filter';
import { useAuth } from '../../../../contexts/auth';
import { ISupervisor } from '../../../../resources/user';

interface IDashboardNavbarFilter {
  periodFilterProps?: IDatePeriodFilter;
}

type CenterValues = {
  centers: string[];
};

type SupervisorValues = {
  supervisors: string[];
};

type TempSearchParams = ApplyValues & CenterValues & SupervisorValues;

const DashboardNavbarFilter: React.FC<IDashboardNavbarFilter> = ({
  periodFilterProps,
}) => {
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const [changeFilter, setChangeFilter] = useState(false);
  const [tempSearchParams, setTempSearchParams] = useState<
    Partial<TempSearchParams>
  >(() => ({
    fromDate: searchParams.has('fromDate')
      ? searchParams.get('fromDate')
      : format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    toDate: searchParams.has('toDate')
      ? searchParams.get('toDate')
      : format(endOfMonth(new Date()), 'yyyy-MM-dd'),
    centers: searchParams.has('centers') ? searchParams.getAll('centers') : [],
    supervisors: searchParams.has('supervisors')
      ? searchParams.getAll('supervisors')
      : [],
  }));

  const period = useMemo(() => {
    const { fromDate } = tempSearchParams;
    const { toDate } = tempSearchParams;
    let label = 'Período';
    if (fromDate && toDate) {
      label = `${format(
        parse(fromDate, 'yyyy-MM-dd', new Date()),
        'dd/MM/yyyy'
      )} à ${format(parse(toDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}`;
    }

    if (fromDate && !toDate) {
      label = `À partir de ${format(
        parse(fromDate, 'yyyy-MM-dd', new Date()),
        'dd/MM/yyyy'
      )}`;
    }

    if (toDate && !fromDate) {
      label = `Até ${format(
        parse(toDate, 'yyyy-MM-dd', new Date()),
        'dd/MM/yyyy'
      )}`;
    }

    return {
      label,
      fromDate,
      toDate,
    };
  }, [tempSearchParams]);

  const center = useMemo(() => {
    const { centers } = tempSearchParams;
    let label = 'Centros';

    if (centers && centers.length > 0) {
      label = `${centers.slice(0, 3).join(', ')}${
        centers.length > 3 ? `, +${centers.length - 3}` : ''
      }`;
    }

    return {
      label,
      centers,
    };
  }, [tempSearchParams]);

  const supervisor = useCallback(
    (supervisors: ISupervisor[]) => {
      const { supervisors: supervisorIds } = tempSearchParams;
      let label = 'Supervisores';

      const s = supervisors.filter((ss) => supervisorIds?.includes(ss.code));

      if (s && s.length > 0) {
        label = `${s
          .map((ss) => {
            const fullname = ss.name.split(/\s/g);
            if (fullname.length >= 2) {
              const firstName = fullname.at(0);
              const lastname = fullname.at(-1);
              return `${firstName} ${lastname}`;
            }
            return ss.name;
          })
          .slice(0, 1)
          .join(', ')}${s.length > 1 ? `, +${s.length - 1}` : ''}`;
      }

      return {
        label,
        supervisors,
      };
    },
    [tempSearchParams]
  );

  const setCenters = useCallback((centers: string[]) => {
    setTempSearchParams((old) => {
      const newParams = { ...old };
      newParams.centers = centers;
      return newParams;
    });
    setChangeFilter(true);
  }, []);

  const setSupervisors = useCallback((supervisors: string[]) => {
    setTempSearchParams((old) => {
      const newParams = { ...old };
      newParams.supervisors = supervisors;
      return newParams;
    });
    setChangeFilter(true);
  }, []);

  const setPeriod = useCallback((range: ApplyValues) => {
    setTempSearchParams((old) => {
      const newParams = { ...old };
      newParams.fromDate = range.fromDate;
      newParams.toDate = range.toDate;
      return newParams;
    });
    setChangeFilter(true);
  }, []);

  const apply = useCallback(() => {
    const newParams = Object.entries(tempSearchParams);
    if (newParams.length > 0) {
      newParams.forEach(([key, value]) => {
        if (value && !isEmpty(value)) {
          if (Array.isArray(value)) {
            searchParams.delete(key);
            value.forEach((v) => {
              searchParams.append(key, v);
            });
          } else {
            searchParams.set(key, value);
          }
        } else {
          searchParams.delete(key);
        }
      });
      setSearchParams(searchParams);
      setChangeFilter(false);
    }
  }, [tempSearchParams, searchParams, setSearchParams]);

  const filtersComponent = useMemo(
    () => (
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        divider={
          <Divider
            flexItem
            orientation="vertical"
          />
        }
        spacing={{ xs: 0, md: 2 }}
        sx={{
          px: { md: 3 },
          flexGrow: 1,
          justifyContent: 'space-around',
          flexWrap: 'nowrap',
        }}
      >
        {/* <MenuDropdown
          icon={<Flag />}
          label="Planta"
        />
        <MenuDropdown
          icon={<BusinessCenter />}
          label="Supervisor"
        /> */}
        <CenterFilter
          button={
            <Button
              color="tertiary"
              endIcon={
                <SvgIcon>
                  <ArrowDropDown />
                </SvgIcon>
              }
              startIcon={<Flag />}
              variant="text"
            >
              {center.label}
            </Button>
          }
          defaultValue={center.centers}
          multiSelected
          onChange={setCenters}
          showAll
        />
        <SupervisorFilter
          button={(params) => (
            <Button
              color="tertiary"
              endIcon={<ArrowDropDown />}
              startIcon={
                params.loading ? (
                  <CircularProgress size={13} />
                ) : (
                  <BusinessCenter />
                )
              }
              variant="text"
            >
              {supervisor(params.supervisors).label}
            </Button>
          )}
          centers={
            (tempSearchParams.centers ?? []).length > 0
              ? tempSearchParams.centers ?? []
              : auth.user?.centers.map(({ id }) => id) ?? []
          }
          defaultValue={tempSearchParams.supervisors}
          multiSelected
          onChange={setSupervisors}
          showAll
        />
        <DatePeriodFilter
          button={
            <Button
              color="tertiary"
              endIcon={<ArrowDropDown />}
              startIcon={<CalendarMonth />}
              variant="text"
            >
              {period.label}
            </Button>
          }
          defaultValue={{
            fromDate: period.fromDate
              ? parse(period.fromDate, 'yyyy-MM-dd', new Date())
              : null,
            toDate: period.toDate
              ? parse(period.toDate, 'yyyy-MM-dd', new Date())
              : endOfMonth(new Date()),
          }}
          onApply={setPeriod}
          setDefaultValueOnReset={{
            fromDate: startOfMonth(new Date()),
            toDate: endOfMonth(new Date()),
          }}
          {...periodFilterProps}
        />
        <Button
          disabled={!changeFilter}
          onClick={apply}
          startIcon={<Refresh />}
        >
          Aplicar
        </Button>
      </Stack>
    ),
    [
      center.label,
      center.centers,
      setCenters,
      tempSearchParams.centers,
      tempSearchParams.supervisors,
      auth.user?.centers,
      setSupervisors,
      period.label,
      period.fromDate,
      period.toDate,
      setPeriod,
      periodFilterProps,
      changeFilter,
      apply,
      supervisor,
    ]
  );

  const theme = useTheme();
  const onlyMobile = useMediaQuery(theme.breakpoints.down('md'));
  const popupState = usePopupState({
    popupId: 'filter-dialog',
    variant: 'dialog',
  });

  if (onlyMobile)
    return (
      <>
        <IconButton
          sx={{ order: 4 }}
          {...bindTrigger(popupState)}
        >
          <FilterList />
        </IconButton>
        <Dialog {...bindDialog(popupState)}>
          <DialogTitle>Filtros</DialogTitle>
          <DialogContent>{filtersComponent}</DialogContent>
        </Dialog>
      </>
    );

  return filtersComponent;
};
export default DashboardNavbarFilter;
