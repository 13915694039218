import { TextFieldElement } from 'react-hook-form-mui';

import { Box, Grid } from '@mui/material';

import ImageInput from './image-input';

const ContentImageForm = () => (
  <Box mt={2}>
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        md={12}
        xs={12}
      >
        <TextFieldElement
          label="Titulo"
          name="image.title"
        />
      </Grid>
      {/* <Grid
        item
        md={6}
        xs={12}
      >
        <TextFieldElement
          label="Subtitulo"
          name="image.subtitle"
        />
      </Grid> */}
      <Grid
        item
        md={12}
      >
        <ImageInput
          label="Imagem"
          name="image.imageUrl"
        />
      </Grid>
    </Grid>
  </Box>
);

export default ContentImageForm;
