import { ReactNode, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Delete, UploadFile } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActionArea,
  FormHelperText,
  FormLabel,
  Grow,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { get } from 'lodash';

interface IImageInput {
  label?: ReactNode;
  name: string;
  sx?: SxProps<Theme>;
}

const generagePreview = (file: File) => {
  const image = URL.createObjectURL(file);

  return image;
};

const ImageInput: React.FC<IImageInput> = ({ name, label, sx }) => {
  const form = useFormContext();

  const [preview, setPreview] = useState<string | undefined>(() => {
    const file = form.watch(name, undefined);
    if (typeof file === 'string') {
      return file;
    }
    if (file) {
      return generagePreview(file);
    }
    return undefined;
  });

  const setPreviewImage = useCallback(
    (file: File) => {
      const image = generagePreview(file);
      form.setValue(name, file);
      setPreview(image);
    },
    [form, name]
  );

  const error = get(form.formState.errors, name);

  return (
    <>
      {!!label && (
        <FormLabel sx={{ display: 'block', mb: 1 }}>{label}</FormLabel>
      )}
      <Card
        elevation={0}
        sx={(theme) => ({
          bgcolor: 'grey.100',
          backgroundImage: `url(${preview})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          border: `2px solid ${
            error ? theme.palette.error.main : 'transparent'
          }`,
        })}
      >
        <CardActionArea
          component="label"
          sx={{
            height: 300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...sx,
          }}
        >
          <input
            accept="image/*"
            hidden
            onChange={async (event: any) => {
              const [file] = event.target.files;
              if (file) {
                setPreviewImage(file);
                form.trigger(name);
              }
            }}
            type="file"
          />
          <Grow
            in={!!preview}
            unmountOnExit
          >
            <Button
              color="error"
              onClick={() => {
                form.setValue(name, undefined);
                setPreview(undefined);
              }}
              variant="contained"
            >
              <Delete />
            </Button>
          </Grow>
          <Grow
            in={!preview}
            unmountOnExit
          >
            <Stack
              alignItems="center"
              spacing={2}
            >
              <UploadFile
                fontSize="large"
                sx={{ color: 'grey.600' }}
              />
              <Typography variant="caption">Carregar Imagem</Typography>
            </Stack>
          </Grow>
        </CardActionArea>
      </Card>
      {error?.message && (
        <FormHelperText
          sx={{ color: (theme) => theme.palette.error.main }}
        >{`${error?.message}`}</FormHelperText>
      )}
    </>
  );
};

export default ImageInput;
