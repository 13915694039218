import React, { useCallback, useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';

import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';

import Content from '../../../../components/layouts/content';
import { resolveAdapter } from '../../../../libraries/validator';
import resources from '../../../../resources';
import ContentForm, { schemaValidation } from '../../components/content-form';
import HeaderForm from '../../components/header-form';

const CreateContent: React.FC = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const form = useForm({
    resolver: resolveAdapter(schemaValidation),
    defaultValues: {
      publishDate: new Date(new Date().setHours(0, 0, 0, 0)),
    },
  });

  const [tags, setTags] = useState<string[]>([]);
  const [centers, setCenters] = useState<string[]>([]);

  const uploadImages = useCallback(async (file: File) => {
    const response = await resources
      .use<'content'>('content')
      ?.uploadImage(file);
    if (response.status === 'OK') return response.payload;
    return undefined;
  }, []);

  const fetchTags = useCallback(async () => {
    const response = await resources.use('content').getTags();
    if (response.status === 'OK') {
      setTags(response.payload ?? []);
    }
  }, []);

  const fetchCenters = useCallback(async () => {
    const response = await resources.use('property').getCenters();
    if (response.status === 'OK') {
      setCenters(response.payload?.map(({ codCenter }) => codCenter) ?? []);
    }
  }, []);

  const save = useCallback(
    async (values: any) => {
      let payload: any = {
        type: values.type,
        lang: values.lang,
        codeCenter: (values.codeCenter ?? [])
          .map((c: any) => c)
          .filter((v: any) => v !== 'Todos'),
        publishDate: format(parseISO(values.publishDate), 'yyyy-MM-dd'),
        tags: values.tags,
      };

      if (payload.type === 'feed') {
        payload.feed = {
          ...values.feed,
          imageUrl:
            values.feed.imageUrl instanceof File
              ? await uploadImages(values.feed.imageUrl)
              : values.feed.imageUrl,
        };
      }

      if (payload.type === 'image') {
        payload.image = {
          ...values.image,
          imageUrl:
            values.image.imageUrl instanceof File
              ? await uploadImages(values.image.imageUrl)
              : values.image.imageUrl,
        };
      }

      if (payload.type === 'youtube') {
        payload.youtube = values.youtube;
      }

      payload = schemaValidation.cast(payload, {
        stripUnknown: true,
      }) as any;

      if ((payload.codeCenter ?? []).length === 0) {
        delete payload.codeCenter;
      }

      const response = await resources
        .use<'content'>('content')
        .create(payload);

      if (response.status === 'OK') {
        navigate('../');
        snackbar.enqueueSnackbar('Conteúdo atualizado com sucesso', {
          variant: 'success',
        });
      } else {
        snackbar.enqueueSnackbar('Não foi possivel criar o conteúdo', {
          variant: 'error',
        });
      }
    },
    [uploadImages, navigate, snackbar]
  );

  useEffect(() => {
    fetchTags();
    fetchCenters();
  }, [fetchTags, fetchCenters]);
  return (
    <Content title={<HeaderForm title="Novo Conteúdo" />}>
      <FormContainer
        formContext={form}
        onSuccess={save}
      >
        <ContentForm
          defaultCenters={centers}
          defaultTags={tags}
          onImageUpload={uploadImages}
        />
      </FormContainer>
    </Content>
  );
};
export default CreateContent;
