import { Box } from '@mui/material';

const Contact = () => (
  <Box
    height="100%"
    sx={{ overflow: 'hidden' }}
  >
    <iframe
      frameBorder={0}
      height="100%"
      src="https://ticket.piracanjuba.bovcontrol.com"
      title="contact"
      width="100%"
    />
  </Box>
);

export default Contact;
