import { Box, Chip, Grow, Stack } from '@mui/material';

import { ChartBar } from '../chart-bar';

export interface IPriceValueChart {
  name: string;
  value: number;
}
interface IPriceChart {
  max: number;
  values: IPriceValueChart[];
  chip?: {
    label: string;
  };
}

const PriceChart: React.FC<IPriceChart> = ({ values, chip }) => (
  <Stack spacing={3}>
    <Grow
      in={!!chip?.label}
      unmountOnExit
    >
      <Chip
        label={chip?.label}
        size="small"
        sx={{
          bgcolor: 'grey.700',
          color: 'white',
          alignSelf: 'center',
          position: 'relative',
        }}
      />
    </Grow>
    <Box
      sx={{
        position: 'relative',
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
    >
      <Stack
        direction="row"
        sx={{
          minHeight: 320,
        }}
      >
        <ChartBar
          values={values.map(({ name, value }) => ({
            name,
            value,
          }))}
        />
      </Stack>
    </Box>
  </Stack>
);

export default PriceChart;
