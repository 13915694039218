export const getUserDescription = (type: string) => {
  switch (type) {
    case 'provider':
      return 'Fornecedores';
    case 'supervisor':
      return 'Supervisores';
    default:
      return 'Usuários';
  }
};
