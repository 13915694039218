import React, { useCallback, useState } from 'react';
import {
  CheckboxElement,
  FormContainer,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui';
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom';

import {
  ArrowDropDown,
  Lock,
  Logout,
  Person,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {
  alpha,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grow,
  IconButton,
  InputAdornment,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useSnackbar } from 'notistack';

import { useAuth } from '../../../../contexts/auth';
import Validator, { resolveAdapter } from '../../../../libraries/validator';
import { cardFormStyles, wrapperStyles } from './styles';

interface ILogin {
  logoUrl: string;
}

interface ILoginPayload {
  username: string;
  password: string;
  remember: boolean;
}

const loginSchemaValidation = Validator.object().shape({
  username: Validator.string().nullable().required().label('Usuário'),
  password: Validator.string().nullable().required().label('Senha'),
});

const Login: React.FC<ILogin> = ({ logoUrl }) => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const auth = useAuth();
  const form = useForm<any>({
    defaultValues: {
      username: auth.lastUserLoggedIn
        ? auth.lastUserLoggedIn?.username || undefined
        : undefined,
      password: undefined,
      remember: !!auth.lastUserLoggedIn,
    },
    resolver: resolveAdapter(loginSchemaValidation),
  });
  const [loginLoading, setLoginLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((old) => !old);
  };

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'user-last-logged-in',
  });

  const login = useCallback(
    async ({ username, password, remember }: ILoginPayload) => {
      setLoginLoading(true);
      const response = await auth.login(username, password, remember);
      setLoginLoading(false);
      if (response.authenticated) {
        navigate('/');
      } else if (response.code === 'USER_INVALID_PERMISSION') {
        snackbar.enqueueSnackbar('Usuário sem permissão de acesso', {
          variant: 'error',
        });
      } else if (response.code === 'USER_PASS_INVALID') {
        snackbar.enqueueSnackbar('Usuário ou senha inválidos', {
          variant: 'error',
        });
      } else {
        snackbar.enqueueSnackbar('Ocorreu um erro ao autenticar', {
          variant: 'error',
        });
      }
    },
    [auth, snackbar, navigate]
  );

  if (auth.authenticated) return <Navigate to={auth.config.baseUrl} />;

  return (
    <Box sx={wrapperStyles}>
      <FormContainer
        formContext={form}
        onSuccess={login}
      >
        <Stack spacing={3}>
          <Grow in>
            <Paper
              elevation={1}
              sx={cardFormStyles}
            >
              <Stack spacing={3}>
                <Box sx={{ mx: 'auto' }}>
                  <img
                    alt="Piracanjuba Pró-Campo"
                    height={130}
                    src={logoUrl}
                  />
                </Box>
                <Typography
                  sx={{ textAlign: 'center' }}
                  variant="caption"
                >
                  Faça seu login para administrar o programa
                </Typography>
                <Stack spacing={1}>
                  <Stack spacing={2}>
                    <Collapse
                      in={!!auth.lastUserLoggedIn}
                      unmountOnExit
                    >
                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                      >
                        <Avatar
                          sx={(theme) => ({
                            bgcolor: alpha(theme.palette.secondary.main, 0.5),
                          })}
                        />
                        <Stack sx={{ flex: 1 }}>
                          <Typography
                            sx={{
                              color: 'grey.500',
                              fontSize: 10,
                              textTransform: 'uppercase',
                            }}
                          >
                            bem vindo novamente
                          </Typography>
                          <Typography
                            sx={{
                              color: 'secondary.main',
                              fontSize: 18,
                            }}
                          >
                            <strong>{auth.lastUserLoggedIn?.name}</strong>
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                        >
                          <IconButton
                            size="small"
                            {...bindTrigger(popupState)}
                          >
                            <ArrowDropDown />
                          </IconButton>
                        </Stack>
                        <Menu
                          {...bindMenu(popupState)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          elevation={3}
                          sx={(theme) => ({
                            '& .MuiAvatar-root': {
                              width: 22,
                              height: 22,
                              bgcolor: alpha(theme.palette.secondary.main, 0.5),
                              ml: -0.5,
                              mr: 2,
                            },
                          })}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          {auth.lastUsersLoggedIn
                            .filter(
                              (user) =>
                                user.username !==
                                auth.lastUserLoggedIn?.username
                            )
                            .map((user) => (
                              <MenuItem
                                key={user.username}
                                onClick={() => {
                                  auth.setLastUserIn(user, 'add');
                                  form.setValue('username', user.username);
                                  form.setValue('remember', true);
                                  popupState.close();
                                }}
                              >
                                <ListItemIcon>
                                  <Avatar />
                                </ListItemIcon>
                                <ListItemText>{user.name}</ListItemText>
                              </MenuItem>
                            ))}
                          {!!auth.lastUserLoggedIn && (
                            <MenuItem
                              onClick={() => {
                                form.reset({
                                  username: undefined,
                                  password: undefined,
                                  remember: true,
                                });
                                auth.setLastUserIn(
                                  auth.lastUserLoggedIn!,
                                  'change'
                                );
                                popupState.close();
                                setTimeout(() => {
                                  form.trigger('username');
                                }, 500);
                              }}
                            >
                              <ListItemIcon>
                                <Logout fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Acessar outra conta</ListItemText>
                            </MenuItem>
                          )}
                        </Menu>
                      </Stack>
                    </Collapse>
                    <Collapse
                      in={!auth.lastUserLoggedIn}
                      unmountOnExit
                    >
                      <TextFieldElement
                        autoFocus
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person color="inherit" />
                            </InputAdornment>
                          ),
                        }}
                        label="usuário"
                        name="username"
                        required
                      />
                    </Collapse>
                    <TextFieldElement
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock color="inherit" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label="senha"
                      name="password"
                      required
                      type={showPassword ? 'text' : 'password'}
                    />
                  </Stack>
                  <Link
                    component={RouterLink}
                    fontSize={12}
                    sx={{ alignSelf: 'flex-end' }}
                    to="/auth/remember-password"
                  >
                    Esqueci minha senha
                  </Link>
                  <CheckboxElement
                    label="Lembra-me do usuário"
                    name="remember"
                  />
                </Stack>
                <Collapse
                  in={loginLoading}
                  sx={{ alignSelf: 'center' }}
                  unmountOnExit
                >
                  <CircularProgress size={25} />
                </Collapse>
                <Collapse
                  in={!loginLoading}
                  unmountOnExit
                >
                  <Button
                    color="primary"
                    disabled={loginLoading}
                    fullWidth
                    sx={{ borderRadius: 20 }}
                    type="submit"
                    variant="contained"
                  >
                    ENTRAR
                  </Button>
                </Collapse>
              </Stack>
            </Paper>
          </Grow>
        </Stack>
      </FormContainer>
    </Box>
  );
};

export default Login;
