import { TextFieldElement } from 'react-hook-form-mui';

import { Box, Grid } from '@mui/material';

import EditorElement, { TextProps } from '../../../components/editor-element';
import ImageInput from './image-input';

interface IContentFeedForm {
  onImageUpload: TextProps['onUpload'];
}

const ContentFeedForm: React.FC<IContentFeedForm> = ({ onImageUpload }) => (
  <Box mt={2}>
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        md={12}
        xs={12}
      >
        <TextFieldElement
          label="Titulo"
          name="feed.title"
        />
      </Grid>
      {/* <Grid
        item
        md={12}
        xs={12}
      >
        <TextFieldElement
          label="Subtitulo"
          name="feed.subtitle"
        />
      </Grid> */}
      <Grid
        item
        md={12}
        xs={12}
      >
        <ImageInput
          label="Imagem Principal"
          name="feed.imageUrl"
        />
      </Grid>
      <Grid
        item
        md={12}
      >
        <EditorElement
          label="Conteúdo"
          name="feed.content"
          onUpload={onImageUpload}
        />
      </Grid>
    </Grid>
  </Box>
);

export default ContentFeedForm;
