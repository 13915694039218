import Http from '../services/http';
import { IPagination } from './pagination';
import { IResourceResponse } from './response';

export interface ICenter {
  id: string;
}
export interface INotification {
  id: string;
  author: string;
  title: string;
  description: string;
  createdAt: string;
  quantitySend: number;
  quantityRead: number;
  importantAlert: boolean;
  pushNotification: boolean;
  url: string;
  attachment: string;
}

export interface IRecipient {
  id: string;
  userName: string;
  userType: 'provider' | 'supervisor';
  codCenter: string;
  read: false;
  dateRead?: string;
}

interface ICreateNotification {
  push: {
    enabled: boolean;
  };
  important: {
    enabled: boolean;
    ok: {
      link: string;
      label: string;
    };
    type: 'text' | 'image';
    image?: File;
    cancel: {
      link: string;
      label: string;
    };
  };
  centers: {
    id: string;
    label: string;
  }[];
  userType: 'provider' | 'client' | 'all';
  title: string;
  message?: string;
  url?: string;
  recipients: IRecipient[];
  attachment?: File;
}
export type IUpdateNotification = Partial<ICreateNotification>;

export interface IGetAllQueryParams {
  page: number;
  fromDate: string | null;
  toDate: string | null;
  codCenters: string[] | null;
}

export interface IGetUsersByNotificationQueryParams {
  page: number;
  codCenters: string[] | null;
}

export interface INotificationResources {
  create(
    payload: ICreateNotification
  ): Promise<IResourceResponse<INotification[]>>;
  getCenters(): Promise<IResourceResponse<ICenter[]>>;
  getUsersByNotification(
    id: string,
    queryParams?: Partial<IGetUsersByNotificationQueryParams>
  ): Promise<IResourceResponse<IPagination<IRecipient>>>;
  getById(
    id: string,
    centers: string[]
  ): Promise<IResourceResponse<INotification>>;
  getAll(
    queryParams?: Partial<IGetAllQueryParams>
  ): Promise<IResourceResponse<IPagination<INotification>>>;
}

const notification: INotificationResources = {
  async create(payload) {
    const formData = new FormData();
    formData.append(
      'pushNotification',
      payload.push?.enabled ? 'true' : 'false'
    );
    formData.append(
      'importantAlertNotification',
      payload.important.enabled ? 'true' : 'false'
    );
    // formData.append('codCenter', payload.centers.map(({ id }) => id).join(','));
    // formData.append('typeUser', payload.userType);
    formData.append(
      'idUsers',
      payload.recipients.map(({ id }) => id).join(',')
    );

    formData.append('title', payload.title);

    if (payload.attachment) {
      formData.append('file', payload.attachment);
    }

    if (payload.message) {
      formData.append('description', payload?.message);
    }

    if (payload.url) {
      formData.append('urlLink', payload?.url);
    }

    if (payload.important.enabled) {
      const actions = [
        {
          description: payload.important.ok?.label ?? 'OK',
          action: payload.important.ok?.link ?? '',
          type: payload.important.ok?.link ? 'link' : 'ok',
        },
      ];

      if (payload.important.cancel.label) {
        actions.push({
          description: payload.important.cancel?.label ?? 'Cancelar',
          action: payload.important.cancel?.link ?? '',
          type: payload.important.cancel?.link ? 'link' : 'cancel',
        });
      }

      formData.append('actions', JSON.stringify(actions));
      if (payload.important.type === 'image') {
        formData.append('file', payload.important.image!);
      }
    }

    const { status, data } = await Http.post(
      `${process.env.REACT_APP_API_NOTIFICATION_URL}/notification`,
      formData
    );

    if (status === 201) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getUsersByNotification(id, params) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_NOTIFICATION_URL}/notification/listUser/${id}`,
      {
        params: {
          ...(params?.page ? { page: params.page } : {}),
          ...(params?.codCenters
            ? { codCenter: params.codCenters.join(',') }
            : {}),
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          data: data.data,
          meta: {
            limit: data.meta.limit,
            page: data.meta.page,
            pages: data.meta.pages,
            records: data.meta.records,
          },
        },
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getCenters() {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_PROPERTY_URL}/property/center`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getById(id: string, centers) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_NOTIFICATION_URL}/notificationList`,
      {
        params: {
          id,
          codCenter: centers.join(','),
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload:
          (data.data ?? []).map((n: any) => ({
            id: n.id,
            author: n.author,
            title: n.title,
            description: n.description,
            quantitySend: n.quantitySend,
            quantityRead: n.quantityRead,
            importantAlert: n.importantAlertNotification,
            pushNotification: n.pushNotification,
            url: n.urlLink,
            attachment: n.file,
            createdAt: n.created_at,
          }))?.[0] ?? null,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getAll(params: IGetAllQueryParams) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_NOTIFICATION_URL}/notificationList`,
      {
        params: {
          ...(params.page ? { page: params.page } : {}),
          fromDate: params.fromDate,
          toDate: params.toDate,
          codCenter: params.codCenters?.join(','),
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          data: (data.data ?? []).map((n: any) => ({
            ...n,
            createdAt: n.created_at,
          })),
          meta: {
            limit: data.meta.limit,
            page: data.meta.page,
            pages: data.meta.pages,
            records: data.meta.records,
          },
        },
      };
    }
    return {
      status: 'FAIL',
    };
  },
};

export default notification;
