import React from 'react';
import { CookiesProvider } from 'react-cookie';

import {
  CssBaseline,
  GlobalStyles,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { SnackbarProvider } from 'notistack';

import AuthProvider from './contexts/auth';
import DialogProvider from './contexts/dialog';
import DrawerProvider from './contexts/drawer';
import SlotProvider from './contexts/slot';
import Routes from './routes';
import theme from './styles';
import globalStyle from './styles/global';

// eslint-disable-next-line no-underscore-dangle
setDefaultOptions({ locale: ptBrLocale });

const App: React.FC = () => (
  <StyledEngineProvider injectFirst>
    <CssBaseline />
    <GlobalStyles styles={globalStyle} />
    <LocalizationProvider
      adapterLocale={ptBrLocale}
      dateAdapter={AdapterDateFns}
    >
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          maxSnack={3}
        >
          <CookiesProvider>
            <SlotProvider>
              <DialogProvider>
                <DrawerProvider>
                  <AuthProvider
                    baseUrl="/"
                    loginUrl="/auth/login"
                  >
                    <Routes />
                  </AuthProvider>
                </DrawerProvider>
              </DialogProvider>
            </SlotProvider>
          </CookiesProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </StyledEngineProvider>
);

export default App;
