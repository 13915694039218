import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Box, Stack } from '@mui/material';

import { useAuth } from '../../../contexts/auth';
import Menu from '../../menu';
import Navbar from './navbar';

export const AuthLayout: React.FC = () => {
  const auth = useAuth();

  if (!auth.authenticated) return <Navigate to={auth.config.loginUrl} />;

  return (
    <Stack sx={{ minHeight: '100vh' }}>
      <Navbar />
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={0}
        sx={{ minHeight: '100%', flexGrow: 1 }}
      >
        <Menu />
        <Box
          sx={{
            position: 'relative',
            minHeight: '100%',
            width: 'auto',
            overflow: 'auto',
            flex: 1,
          }}
        >
          <Outlet />
        </Box>
      </Stack>
    </Stack>
  );
};
