import React from 'react';
import { Route, Routes } from 'react-router-dom';

import DefaultDashboard from './elements/default-dashboard';

const Dashboard: React.FC = () => (
  <Routes>
    <Route
      element={<DefaultDashboard />}
      index
    />
  </Routes>
);

export default Dashboard;
