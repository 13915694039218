import { useMemo } from 'react';

import { Box, Paper, Stack, useTheme } from '@mui/material';

import { getVideoCode } from '../helpers/youtube';
import htmlBase from './preview-html';

interface IContentPreview {
  values: any;
}

const ContentPreview: React.FC<IContentPreview> = ({ values }) => {
  const theme = useTheme();
  const { type, feed, image, youtube } = values;

  const preview = useMemo(() => {
    let htmltext = '';
    switch (type) {
      case 'feed': {
        const mainImage =
          typeof feed?.imageUrl === 'object'
            ? URL.createObjectURL(
                new Blob([feed?.imageUrl], {
                  type: feed?.imageUrl.type,
                })
              )
            : feed?.imageUrl;

        htmltext = `
        <h1 class="title">${feed?.title ?? 'Titulo'}</h1>
        <img class="main-image" src="${
          mainImage ?? 'https://via.placeholder.com/400x300?text=Imagem'
        }" />
        <div class="html">${feed?.content ?? 'Conteúdo'}</div>
      `;
        break;
      }
      case 'image': {
        const mainImage =
          typeof image?.imageUrl === 'object'
            ? URL.createObjectURL(
                new Blob([image?.imageUrl], {
                  type: image?.imageUrl.type,
                })
              )
            : image?.imageUrl;

        htmltext = `
        <h1 class="title">${image?.title ?? 'Titulo'}</h1>
        <img class="main-image" src="${
          mainImage ?? 'https://via.placeholder.com/400x300?text=Imagem'
        }" />
      `;
        break;
      }
      case 'youtube': {
        htmltext = `
        <h1 class="title">${youtube?.title ?? 'Titulo'}</h1>
        <iframe width="100%"  src="https://www.youtube.com/embed/${getVideoCode(
          youtube?.videoUrl
        )}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      `;
        break;
      }
      default:
        htmltext = 'Titulo';
    }

    const html = htmlBase(theme).replace('$content', htmltext);

    const blob = new Blob([html], {
      type: 'text/html',
    });

    return URL.createObjectURL(blob);
  }, [
    feed?.content,
    feed?.imageUrl,
    feed?.title,
    image?.imageUrl,
    image?.title,
    theme,
    type,
    youtube?.title,
    youtube?.videoUrl,
  ]);

  return (
    <Paper
      elevation={10}
      sx={{
        maxHeight: 600,
        minHeight: 600,
        height: 1,
        p: 2,
        pt: 2,
        bgcolor: 'black',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack alignItems="center">
        <Box
          bgcolor="grey.900"
          borderRadius={25}
          height={5}
          my={2}
          width={50}
        />
      </Stack>
      <Box
        component="iframe"
        frameBorder={0}
        src={preview}
        sx={{ flex: 1, width: 1 }}
      />
      <Stack alignItems="center">
        <Box
          bgcolor="grey.900"
          borderRadius={25}
          height={50}
          mt={2}
          width={50}
        />
      </Stack>
    </Paper>
  );
};

export default ContentPreview;
