import { Skeleton, Stack, Typography } from '@mui/material';

export const LoadingChart = () => (
  <Stack alignItems="center">
    <Stack
      direction="row"
      spacing={2}
    >
      {new Array(10).fill(null).map((_, key) => (
        <Skeleton
          height={Math.random() * (100 - 50) + 50}
          key={key}
          sx={{ flexGrow: 1 }}
          width={30}
        />
      ))}
    </Stack>
    <Typography sx={{ fontSize: 14, color: 'grey.300' }}>Carregando</Typography>
  </Stack>
);
