import React, {
  ComponentType,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  AttachFile,
  Check,
  CheckCircle,
  ExpandLess,
  NotificationsActive,
  TripOrigin,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  FormLabel,
  Grid,
  Link,
  Pagination,
  Paper,
  Stack,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { format, isValid, parseISO } from 'date-fns';
import { groupBy } from 'lodash';
import { useSnackbar } from 'notistack';

import NotificationNavbarFilter from '../../../../components/filters/notification-navbar-filter';
import Content from '../../../../components/layouts/content';
import Loading from '../../../../components/loading';
import { Slot } from '../../../../contexts/slot';
import resources from '../../../../resources';
import { INotification, IRecipient } from '../../../../resources/notification';
import { IPagination } from '../../../../resources/pagination';
import HeaderForm from '../../components/header-form';

interface IText {
  icon: ComponentType<SvgIconProps>;
  text: ReactNode;
  badge?: number;
  variant: 'success' | 'default';
}

const Text: React.FC<IText> = ({ icon: Icon, text, badge, variant }) => (
  <Stack
    alignItems="center"
    direction="row"
    spacing={1}
  >
    <Icon
      sx={{
        fontSize: 20,
        color: variant === 'success' ? 'success.main' : 'grey.300',
      }}
    />
    <Typography sx={{ fontSize: 14, flex: 1, color: 'grey.600' }}>
      {text}
    </Typography>
    {badge !== undefined && (
      <Chip
        color={variant === 'success' ? 'success' : 'default'}
        label={badge}
        size="small"
      />
    )}
  </Stack>
);

const DetailNotification: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(0);
  const [notification, setNotification] = useState<INotification>();

  const [recipients, setRecipients] = useState<IRecipient[]>([]);
  const [recipientsMeta, setRecipientsMeta] = useState<
    IPagination<IRecipient>['meta']
  >({
    limit: 0,
    page: 0,
    pages: 0,
    records: 0,
  });

  const recipientGroups = useMemo(
    () =>
      Object.entries(
        groupBy(recipients, (recipient) => recipient.codCenter)
      ).map(([codCenter, r]) => ({
        codCenter,
        recipients: r,
      })),
    [recipients]
  );

  const fetchUsersNotification = useCallback(async () => {
    const response = await resources
      .use('notification')
      .getUsersByNotification(id!, {
        ...(searchParams.has('user-page')
          ? { page: +searchParams.get('user-page')! }
          : {}),
        ...(searchParams.has('centers')
          ? { codCenters: searchParams.getAll('centers')! }
          : {}),
      });

    if (response.status === 'OK' && response.payload) {
      setRecipients(response.payload.data ?? []);
      setRecipientsMeta(response.payload.meta);
    }
  }, [id, searchParams]);

  const fetchNotification = useCallback(async () => {
    let centers = searchParams.has('centers')
      ? Array.from(searchParams.getAll('centers'))
      : null;
    if (!centers) {
      const response = await resources.use('property').getCenters();
      centers = response.payload?.map((center) => center.codCenter) ?? [];
    }

    const response = await resources.use('notification').getById(id!, centers);

    if (response.status === 'OK' && response.payload) {
      setNotification(response.payload);
    } else {
      enqueueSnackbar('Notificação não encontrada');
    }
  }, [enqueueSnackbar, id, searchParams]);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchNotification(), fetchUsersNotification()])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        enqueueSnackbar('Ocorreu um erro ao carregar o detalhe da notificação');
        navigate('.');
      });
  }, [enqueueSnackbar, fetchNotification, fetchUsersNotification, navigate]);

  if (!notification) return null;

  return (
    <>
      <Slot name="navbar">
        <NotificationNavbarFilter />
      </Slot>
      <Loading
        loading={loading}
        paperProps={{ sx: { m: 6 } }}
      />
      <Collapse
        in={!loading}
        unmountOnExit
      >
        <Content title={<HeaderForm title="Notificação enviada" />}>
          <Stack
            direction="column"
            spacing={1}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                md={5}
                xs={12}
              >
                <Paper sx={{ p: 6, minHeight: '100%' }}>
                  <Stack
                    direction="column"
                    spacing={2}
                  >
                    <Stack spacing={1}>
                      <Text
                        icon={CheckCircle}
                        text={
                          <>
                            Notificação <strong>Push</strong>
                          </>
                        }
                        variant={
                          notification.pushNotification ? 'success' : 'default'
                        }
                      />
                      <Text
                        icon={CheckCircle}
                        text={
                          <>
                            Alerta <strong>Importante</strong>
                          </>
                        }
                        variant={
                          notification.importantAlert ? 'success' : 'default'
                        }
                      />
                    </Stack>
                    <Divider />
                    <Stack spacing={1}>
                      <Text
                        badge={notification.quantitySend}
                        icon={TripOrigin}
                        text={<>Total enviado</>}
                        variant="default"
                      />
                      <Text
                        badge={notification.quantityRead}
                        icon={Check}
                        text={<>Total lido</>}
                        variant="success"
                      />
                    </Stack>
                    <Divider />
                    <Stack
                      spacing={1}
                      sx={{ color: 'grey.600' }}
                    >
                      <Typography>
                        Enviada em{' '}
                        <strong>
                          {format(
                            new Date(notification.createdAt),
                            "dd/MM/yyyy 'às' HH:mm"
                          )}
                        </strong>
                      </Typography>
                      <Typography>
                        Autor{' '}
                        <strong>{notification.author ?? 'Desconhecido'}</strong>
                      </Typography>
                    </Stack>
                    <Divider />
                    <Stack spacing={3}>
                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                        sx={{ color: 'success.main' }}
                      >
                        <NotificationsActive />{' '}
                        <Typography>Notificação</Typography>
                      </Stack>
                      {!!notification.title && (
                        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>
                          {notification.title}
                        </Typography>
                      )}
                      {!!notification.description && (
                        <Typography>{notification.description}</Typography>
                      )}
                      {!!notification.attachment && (
                        <Link
                          href={notification.url}
                          target="_blank"
                        >
                          {notification.url}
                        </Link>
                      )}
                      {notification.attachment && (
                        <Button
                          component="a"
                          download="true"
                          href={notification.attachment}
                          size="small"
                          startIcon={<AttachFile />}
                          sx={{ justifySelf: 'start' }}
                          target="_blank"
                        >
                          Anexo
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
              <Grid
                item
                md={7}
                xs={12}
              >
                <Paper
                  sx={{
                    p: 6,
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Stack
                    direction="column"
                    spacing={3}
                    sx={{ flexGrow: 1 }}
                  >
                    <FormLabel>
                      Destinatários{' '}
                      <Chip
                        label={recipients.length}
                        size="small"
                      />
                    </FormLabel>
                    <Divider flexItem />
                    <Box
                      sx={{
                        flexGrow: 1,
                        flexBasis: 1,
                        overflow: 'auto',
                      }}
                    >
                      <Stack
                        direction="column"
                        spacing={0}
                      >
                        {recipientGroups.map((group, key) => (
                          <Stack
                            key={key}
                            spacing={1}
                            sx={{ mt: key > 0 ? 1 : 0 }}
                          >
                            <Stack
                              alignItems="center"
                              component="a"
                              direction="row"
                              justifyContent="space-between"
                              onClick={() => setExpanded(key)}
                              spacing={1}
                              sx={{
                                bgcolor: 'grey.100',
                                px: 2,
                                py: 1,
                                borderRadius: 5,
                                cursor: 'pointer',
                              }}
                            >
                              <Typography
                                sx={{
                                  color: 'grey.600',
                                  fontWeight: 'bold',
                                  flex: 1,
                                }}
                              >
                                {group.codCenter}
                              </Typography>
                              <Typography sx={{ color: 'grey.500' }}>
                                {group.recipients.length} destinatários
                              </Typography>
                              {recipientGroups.length > 1 && (
                                <ExpandLess
                                  fontSize="small"
                                  sx={(theme) => ({
                                    transform: `rotate(${
                                      expanded === key ? 180 : 0
                                    }deg)`,
                                    transition:
                                      theme.transitions.create('transform'),
                                  })}
                                />
                              )}
                            </Stack>
                            <Collapse
                              in={expanded === key}
                              key={key}
                              unmountOnExit
                            >
                              <Stack
                                spacing={0.5}
                                sx={{ mx: 2 }}
                              >
                                {group.recipients.map((recipient, rkey) => (
                                  <Stack
                                    alignItems="center"
                                    direction="row"
                                    key={rkey}
                                    spacing={2}
                                  >
                                    {recipient.read ? (
                                      <Check
                                        sx={{
                                          fontSize: 20,
                                          color: 'success.main',
                                        }}
                                      />
                                    ) : (
                                      <TripOrigin
                                        sx={{ fontSize: 20, color: 'grey.300' }}
                                      />
                                    )}
                                    <Typography
                                      sx={{ flex: 1, color: 'grey.600' }}
                                    >
                                      {recipient.userName}
                                    </Typography>
                                    <Chip
                                      label={
                                        recipient.userType === 'provider'
                                          ? 'Produtor'
                                          : recipient.userType === 'supervisor'
                                          ? 'Supervisor'
                                          : recipient.userType ===
                                            'collaborator'
                                          ? 'Colaborador'
                                          : 'Outro'
                                      }
                                      size="small"
                                    />
                                    {!!recipient.dateRead &&
                                      !!isValid(recipient.dateRead) && (
                                        <Typography
                                          sx={{
                                            color: 'grey.600',
                                            fontSize: 13,
                                          }}
                                        >
                                          lido em{' '}
                                          {format(
                                            parseISO(recipient.dateRead),
                                            "dd/MM/yyyy 'às' HH:mm"
                                          )}
                                        </Typography>
                                      )}
                                  </Stack>
                                ))}
                              </Stack>
                            </Collapse>
                          </Stack>
                        ))}
                      </Stack>
                    </Box>
                    {recipientsMeta.pages > 1 && (
                      <Pagination
                        count={recipientsMeta.pages}
                        onChange={(_, page) => {
                          if (page > 1) {
                            searchParams.set('user-page', `${page}`);
                          } else {
                            searchParams.delete('user-page');
                          }
                          setSearchParams(searchParams);
                        }}
                        page={recipientsMeta.page}
                        size="small"
                        sx={{ justifySelf: 'center' }}
                      />
                    )}
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </Stack>
        </Content>
      </Collapse>
    </>
  );
};
export default DetailNotification;
