import encode from 'jwt-encode';

const TIME_TO_REGENERATE = 30;

export const generateSecretToken = () => {
  const exp = new Date();
  exp.setSeconds(exp.getSeconds() + TIME_TO_REGENERATE);

  const value = encode(
    {
      exp: exp.getTime(),
    },
    process.env.REACT_APP_API_ENTITLEMENT_SECRET as string
  );

  return value;
};

export const withProductSecurity = (data: Record<any, any>) => ({
  ...data,
  product_id: process.env.REACT_APP_API_ENTITLEMENT_CLIENT_ID,
  product_token: generateSecretToken(),
});
