import Http from '../services/http';
import { IResourceResponse } from './response';

interface IGetQualityByIdentity {
  identity: string;
}

export interface IQuality {
  fat: 0;
  prot: 0;
  cbt: 0;
  ccs: 0;
  est: 0;
  esd: 0;
}

interface IGetQualityDataOverview {
  fromDate: string | null;
  toDate: string | null;
  centers: string[];
  supervisors?: string[];
}

interface IResultsOutsideTheQualityStandardReportsDownloadPayload {
  fromDate: string | null;
  toDate: string | null;
  codCenters: string[] | null;
}

export interface IQualityOverview {
  qualityData: {
    averageFat: number;
    averageProt: number;
    averageCbt: number;
    averageCcs: number;
    averageEst: number;
    averageEsd: number;
  };
  qualityVariation: {
    fatVariation: number;
    protVariation: number;
    cbtVariation: number;
    ccsVariation: number;
    estVariation: number;
    esdVariation: number;
  };
  patternIndicator: {
    fatMin: number;
    protMin: number;
    cbtMax: number;
    ccsMax: number;
    estMin: number;
    esdMin: number;
  };
}

export interface IQualityResources {
  getLastResultQuality({
    identity,
  }: IGetQualityByIdentity): Promise<IResourceResponse<IQuality>>;
  qualityDataOverview({
    centers,
    fromDate,
    supervisors,
    toDate,
  }: IGetQualityDataOverview): Promise<IResourceResponse<IQualityOverview>>;
  resultsOutsideTheQualityStandardReportsDownload({
    fromDate,
    toDate,
    codCenters,
  }: Partial<IResultsOutsideTheQualityStandardReportsDownloadPayload>): Promise<
    IResourceResponse<any>
  >;
}

const quality: IQualityResources = {
  async qualityDataOverview({ supervisors, fromDate, toDate, centers }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_QUALITY_URL}/qualityDataOverview`,
      {
        params: {
          ...((supervisors ?? [])?.length > 0
            ? { codSupervisor: (supervisors ?? []).join(',') }
            : {}),
          codCenter: centers.join(','),
          fromDate,
          toDate,
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getLastResultQuality({ identity }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_QUALITY_URL}/qualityLastResult/${identity}`
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async resultsOutsideTheQualityStandardReportsDownload({
    codCenters,
    fromDate,
    toDate,
  }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_QUALITY_URL}/quality/compliance/report`,
      {
        responseType: 'blob',
        params: {
          fromDate,
          toDate,
          codCenter: codCenters?.join(','),
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
};

export default quality;
