import {
  cloneElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Checkbox, Menu, MenuItem } from '@mui/material';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import resources from '../../../resources';

interface ITagsFilter {
  onChange(tags: string[]): void;
  button: ReactNode;
  defaultValue?: string[];
}

const TagsFilter: React.FC<ITagsFilter> = ({
  onChange,
  defaultValue = [],
  button,
}) => {
  const [tags, setTags] = useState<string[]>(defaultValue);
  const [selected, setSelected] = useState<string[]>(defaultValue ?? []);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'tags-filter',
  });

  const fetchTags = useCallback(async () => {
    const response = await resources.use('content').getTags();

    if (response.status === 'OK') {
      setTags(response.payload ?? []);
    }
  }, []);

  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  return (
    <>
      {cloneElement(button as any, {
        ...bindTrigger(popupState),
      })}
      <Menu
        {...bindMenu(popupState)}
        PaperProps={{
          sx: { bgcolor: 'background.paper' },
        }}
      >
        {tags.length === 0 && <MenuItem disabled>Nenhuma tags</MenuItem>}
        {tags.map((t, key) => (
          <MenuItem
            key={key}
            onClick={() => {
              let newSelected;
              if (!selected.includes(t)) {
                newSelected = [...selected.filter((oo: string) => oo !== t), t];
              } else {
                newSelected = [...selected.filter((oo: string) => oo !== t)];
              }

              setSelected(newSelected);
              onChange(newSelected);
            }}
          >
            <Checkbox
              checked={selected.includes(t)}
              disableRipple
              edge="start"
            />
            {t}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TagsFilter;
