import { cloneElement, ReactNode, useMemo, useState } from 'react';

import { Checkbox, Menu, MenuItem } from '@mui/material';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import { useAuth } from '../../../contexts/auth';

interface ICenterFilter {
  onChange(centers: string[]): void;
  button: ReactNode;
  defaultValue?: string[];
  multiSelected?: boolean;
  showAll?: boolean;
}

const CenterFilter: React.FC<ICenterFilter> = ({
  onChange,
  defaultValue = [],
  multiSelected = true,
  showAll = false,
  button,
}) => {
  const auth = useAuth();
  const [selected, setSelected] = useState<string[]>(defaultValue ?? []);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'centers-filter',
  });

  const centers = useMemo(
    () => (auth.user?.centers ?? []).map(({ id }) => id) ?? [],
    [auth.user?.centers]
  );

  const isCenterSelected = useMemo(
    () => centers.every((c) => selected.includes(c)),
    [centers, selected]
  );

  return (
    <>
      {cloneElement(button as any, {
        ...bindTrigger(popupState),
      })}
      <Menu
        {...bindMenu(popupState)}
        PaperProps={{
          sx: { bgcolor: 'background.paper' },
        }}
      >
        {!!showAll && (
          <MenuItem
            onClick={() => {
              if (isCenterSelected) {
                onChange([]);
                setSelected([]);
              } else {
                onChange(centers);
                setSelected(centers);
              }
            }}
          >
            <Checkbox
              checked={isCenterSelected}
              disableRipple
              edge="start"
            />
            Todos
          </MenuItem>
        )}
        {(centers ?? []).map((t, key) => (
          <MenuItem
            key={key}
            onClick={() => {
              let newSelected;
              if (!selected.includes(t)) {
                if (multiSelected) {
                  newSelected = [
                    ...selected.filter((oo: string) => oo !== t),
                    t,
                  ];
                } else {
                  newSelected = [t];
                }
              } else {
                newSelected = [...selected.filter((oo: string) => oo !== t)];
              }

              setSelected(newSelected);
              onChange(newSelected);
            }}
          >
            <Checkbox
              checked={selected.includes(t)}
              disableRipple
              edge="start"
            />
            {t}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CenterFilter;
