import React, { ReactNode } from 'react';

import { Stack } from '@mui/material';

interface IHeaderForm {
  title: ReactNode;
}

const HeaderForm: React.FC<IHeaderForm> = ({ title }) => (
  <Stack
    alignItems="center"
    direction="row"
    spacing={1}
  >
    <span>{title}</span>
  </Stack>
);

export default HeaderForm;
