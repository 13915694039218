import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Login from './elements/login';
import RememberPassword from './elements/remember-password';

export interface IAuth {
  logoUrl: string;
}

const Auth: React.FC<IAuth> = ({ logoUrl }) => (
  <Routes>
    <Route
      element={<Login logoUrl={logoUrl} />}
      path="login"
    />
    <Route
      element={<RememberPassword logoUrl={logoUrl} />}
      path="remember-password"
    />
  </Routes>
);

export default Auth;
