import { ReactNode } from 'react';

import { Box, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';

import ReportNumbersCardItem, {
  IReportNumbersCardItem,
} from './report-numbers-card-item';

interface IReportNumbersCard {
  title: ReactNode;
  items: IReportNumbersCardItem[];
  loading?: boolean;
}

const ReportNumbersCard: React.FC<IReportNumbersCard> = ({
  title,
  items,
  loading,
}) => (
  <Stack
    alignItems="stretch"
    component={Paper}
    justifyContent="space-between"
    spacing={2}
    sx={{ p: 2, minHeight: '100%' }}
  >
    {loading ? (
      <Skeleton
        animation="wave"
        height={25}
        sx={{ maxWidth: 1 }}
        width={150}
      />
    ) : (
      <Typography sx={{ color: 'success.main', fontWeight: 'bold' }}>
        {title}
      </Typography>
    )}
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={1}
      >
        {items.map((item, key) => (
          <Grid
            item
            key={key}
            md="auto"
            xs={6}
          >
            <ReportNumbersCardItem
              {...item}
              loading={loading}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  </Stack>
);

export default ReportNumbersCard;
