import React, { useCallback } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import Content from '../../../../components/layouts/content';
import { resolveAdapter } from '../../../../libraries/validator';
import resources from '../../../../resources';
import HeaderForm from '../../components/header-form';
import NotificationForm, {
  schemaValidation,
} from '../../components/notification-form';

const CreateNotification: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const form = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: {
      important: {
        ok: { label: 'OK', link: undefined },
        cancel: { label: 'Cancelar', link: undefined },
      },
    },
    resolver: resolveAdapter(schemaValidation, {
      stripUnknown: true,
    }),
  });

  const save = useCallback(
    async (values: any) => {
      const response = await resources.use('notification').create({
        ...values,
      });

      if (response.status === 'OK') {
        navigate('..');
        enqueueSnackbar('Nova notificação criada com sucesso', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(
          response.message ?? 'Ocorreu um erro ao criar uma nova notificação',
          { variant: 'error' }
        );
      }
    },
    [enqueueSnackbar, navigate]
  );

  return (
    <Content title={<HeaderForm title="Enviar nova notificação" />}>
      <FormContainer
        formContext={form}
        onSuccess={save}
      >
        <NotificationForm />
      </FormContainer>
    </Content>
  );
};
export default CreateNotification;
