import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, Chip, Collapse, Grow, Stack } from '@mui/material';
import { addHours, endOfMonth, format, parseISO, startOfMonth } from 'date-fns';

import { useAuth } from '../../../../contexts/auth';
import resources from '../../../../resources';
import { IVolumeDayOverview } from '../../../../resources/volume';
import { ChartBar } from '../chart-bar';
import ChartLine, { IChartLineValues } from '../chart-line';
import { LoadingChart } from '../loading-chart';

export interface IVolumeValueChart {
  name: string;
  value: number;
  date: Date;
}
interface IVolumeChart {
  values: IVolumeValueChart[];
  max: number;
  chip?: {
    label: string;
  };
}

const VolumeChart: React.FC<IVolumeChart> = ({ values, chip }) => {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const [dayValues, setDayValues] = useState<
    IVolumeDayOverview['graphicDay'] | undefined
  >();

  const [loadingDayChart, setLoadingDayChart] = useState(false);

  const dayChartValues = useMemo<IChartLineValues | undefined>(() => {
    if (!dayValues) return undefined;
    return {
      items:
        dayValues.map(({ date, totalVolume }: any) => ({
          name: format(addHours(parseISO(date), 3), 'dd/MMMM'),
          value: totalVolume,
        })) ?? [],
      max: 500,
    };
  }, [dayValues]);

  const fetchDayChartValues = useCallback(
    async (date: Date) => {
      const supervisors = searchParams.has('supervisors')
        ? searchParams.getAll('supervisors')
        : [];
      const centers = searchParams.has('centers')
        ? searchParams.getAll('centers')
        : auth.user?.centers.map(({ id }) => id) ?? [];

      const startDate = startOfMonth(date);
      const endDate = endOfMonth(date);

      setLoadingDayChart(true);
      const response = await resources
        .use('volume')
        .getVolumeGraphicDayOverview({
          toDate: format(endDate, 'yyyy-MM-dd'),
          fromDate: format(startDate, 'yyyy-MM-dd'),
          centers,
          supervisors,
        });
      setLoadingDayChart(false);

      if (response.status === 'OK') {
        setDayValues(response.payload?.graphicDay ?? undefined);
      }
    },
    [auth.user?.centers, searchParams]
  );

  return (
    <Stack spacing={3}>
      <Grow
        in={!!chip?.label && !dayChartValues && !loadingDayChart}
        unmountOnExit
      >
        <Chip
          label={chip?.label}
          size="small"
          sx={{
            bgcolor: 'grey.700',
            color: 'white',
            alignSelf: 'center',
            position: 'relative',
          }}
        />
      </Grow>
      <Collapse
        in={loadingDayChart}
        unmountOnExit
      >
        <LoadingChart />
      </Collapse>
      <Box
        sx={{
          position: 'relative',
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        <Collapse
          in={!dayChartValues && !loadingDayChart}
          unmountOnExit
        >
          <Stack
            direction="row"
            sx={{
              minHeight: 320,
            }}
          >
            <ChartBar
              onClick={({ name }) => {
                const value = values.find((v) => v.name === name);
                if (value) {
                  fetchDayChartValues(value.date);
                }
              }}
              values={values.map(({ name, value }) => ({
                name,
                value,
              }))}
              valuesOptions={{
                maximumFractionDigits: 3,
                minimumFractionDigits: 0,
              }}
            />
          </Stack>
        </Collapse>
        <Collapse
          in={dayChartValues !== undefined}
          unmountOnExit
        >
          <ChartLine
            values={dayChartValues!}
            valuesOptions={{
              maximumFractionDigits: 3,
              minimumFractionDigits: 0,
            }}
          />
        </Collapse>
      </Box>
    </Stack>
  );
};

export default VolumeChart;
