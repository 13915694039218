import { useMemo } from 'react';

import {
  Avatar,
  Box,
  Divider,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import logo from '../../../assets/logo-pro-campo.svg';
import { useAuth } from '../../../contexts/auth';
import { Outlet } from '../../../contexts/slot';

const User: React.FC = () => {
  const auth = useAuth();
  const theme = useTheme();
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'));
  const firstLetters = useMemo(
    () =>
      auth.user?.name
        .split(/\s/g)
        .map((name, index) => {
          if (index < 2) {
            return name[0];
          }
          return null;
        })
        .filter(Boolean),
    [auth.user?.name]
  );
  return (
    <Stack
      alignItems="center"
      direction={{ xs: 'row-reverse', md: 'row' }}
      spacing={1}
    >
      {!onlyXs && (
        <Typography
          sx={{ fontSize: 14, fontWeight: 'normal' }}
          variant="caption"
        >
          <strong>{auth.user?.name}</strong>
        </Typography>
      )}
      <Avatar>{firstLetters}</Avatar>
    </Stack>
  );
};

const Navbar = () => {
  const theme = useTheme();
  const onlyMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Paper
      elevation={1}
      square
      sx={(t) => ({
        py: 2,
        px: { xs: 2, md: 4 },
        pr: { xs: 2, md: 4 },
        position: 'sticky',
        top: 0,
        backgroundColor: t.palette.common.white,
        zIndex: t.zIndex.appBar,
      })}
    >
      <Stack
        alignItems="center"
        direction={{ xs: 'row', md: 'row' }}
        divider={
          !onlyMobile && (
            <Divider
              flexItem
              orientation="vertical"
              sx={{ xs: 'none', md: 'inherit' }}
            />
          )
        }
        flexWrap={{ xs: 'wrap' }}
        justifyContent="space-between"
        spacing={3}
      >
        <Box
          sx={{
            minWidth: 150,
            width: { xs: 'auto', lg: 237 },
            px: { xs: 1, md: 2 },
            py: { xs: 0.5, md: 1 },
            textAlign: 'center',
            order: 0,
            // mr: 'auto',
            my: -2,
          }}
        >
          <img
            alt="Piracanjuba PRÓ-Campo"
            src={logo}
            style={{ maxWidth: '100%', maxHeight: 70, minHeight: 70 }}
          />
        </Box>
        <Box flexGrow={{ xs: 0, md: 1 }}>
          <Outlet name="navbar" />
        </Box>
        {/* <NavbarFilter /> */}
        <User />
      </Stack>
    </Paper>
  );
};
export default Navbar;
