import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Person } from '@mui/icons-material';
import {
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { endOfMonth, format, parseISO, startOfMonth } from 'date-fns';

import Content from '../../../../../../components/layouts/content';
import { Slot } from '../../../../../../contexts/slot';
import { cpfFormat } from '../../../../../../helpers/document';
import { phoneFormat } from '../../../../../../helpers/phone';
import resources from '../../../../../../resources';
import { IUserData } from '../../../../../../resources/user';
import HeaderForm from '../../../../components/header-form';
import UserDetailNavbarFilter from '../../../../components/user-detail-navbar-filter';
// import { IUserItem } from '../user-list';

interface IText {
  title: ReactNode;
  value: ReactNode;
}

const Text: React.FC<IText> = ({ title, value }) => (
  <Stack
    direction="column"
    justifyContent="space-between"
  >
    <Typography
      sx={{
        color: 'grey.500',
        fontSize: 14,
      }}
    >
      {title}
    </Typography>
    <Typography
      sx={{
        fontSize: 14,
        fontWeight: 'bold',
      }}
    >
      {value ?? '-'}
    </Typography>
  </Stack>
);

const UserSupervisorDetail: React.FC = () => {
  const { cpf } = useParams<{ cpf: string }>();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState<boolean>(false);

  const [user, setUser] = useState<IUserData>();

  const filterParams = useMemo(
    () => ({
      toDate: searchParams.has('toDate')
        ? parseISO(searchParams.get('toDate')!)
        : endOfMonth(new Date()),
      fromDate: searchParams.has('fromDate')
        ? parseISO(searchParams.get('fromDate')!)
        : startOfMonth(new Date()),
      centers: searchParams.getAll('centers') ?? [],
    }),
    [searchParams]
  );

  const getParams = (query: {
    fromDate: Date | null;
    toDate: Date | null;
    centers: string[] | null;
  }) => ({
    fromDate: query.fromDate
      ? format(new Date(query.fromDate), 'yyyy-MM-dd')
      : format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    toDate: query.toDate
      ? format(new Date(query.toDate), 'yyyy-MM-dd')
      : format(endOfMonth(new Date()), 'yyyy-MM-dd'),
    codCenters: query.centers ?? [],
  });

  const fetchUser = useCallback(async () => {
    const params = getParams(filterParams);
    let { codCenters } = params ?? [];
    if (codCenters.length === 0) {
      const { payload } = await resources.use('property').getCenters();
      codCenters = payload?.map((c) => c.codCenter) ?? [];
    }
    const response = await resources.use('user').userDataByCpf({
      cpf: cpf!,
      codCenters,
    });

    if (response.status === 'OK') {
      setUser(response.payload);
    }
  }, [cpf, filterParams]);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchUser()]).finally(() => {
      setLoading(false);
    });
  }, [fetchUser]);

  return (
    <>
      <Slot name="navbar">
        <UserDetailNavbarFilter
          periodFilterProps={{
            defaultValue: {
              fromDate: filterParams.fromDate,
              toDate: filterParams.toDate,
            },
          }}
        />
      </Slot>
      <Content
        backButton="./../.."
        title={<HeaderForm title="Supervisor" />}
      >
        <Stack
          direction="column"
          spacing={1}
        >
          <Grid
            alignItems="flex-start"
            container
            spacing={2}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <Paper sx={{ p: 4 }}>
                <Stack
                  direction="column"
                  spacing={3}
                >
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography
                      sx={{
                        color: 'success.main',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Person sx={{ mr: 1 }} /> Perfil do Usuário
                    </Typography>
                    <Typography
                      sx={{
                        color: user?.userData.invitation
                          ? 'success.main'
                          : 'warning.main',
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}
                    >
                      {loading ? (
                        <Skeleton
                          animation="wave"
                          width={50}
                        />
                      ) : user?.userData.invitation ? (
                        'Ativo'
                      ) : (
                        'Inativo'
                      )}
                    </Typography>
                  </Stack>
                  <Stack direction="column">
                    <Typography
                      sx={{
                        color: 'grey.700',
                        fontSize: 22,
                        fontWeight: 'bold',
                      }}
                    >
                      {loading ? (
                        <Skeleton
                          animation="wave"
                          height={40}
                          sx={{ width: 1 / 2 }}
                        />
                      ) : (
                        user?.userData.name
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        color: 'grey.700',
                        fontSize: 12,
                      }}
                    >
                      {loading ? (
                        <Skeleton
                          animation="wave"
                          sx={{
                            width: 1 / 1.5,
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          }}
                        />
                      ) : (
                        <>
                          Código do Supervisor:{' '}
                          <strong>{user?.userData.code}</strong>
                        </>
                      )}
                    </Typography>
                  </Stack>
                  <Divider />
                  <Stack
                    direction="column"
                    spacing={2}
                  >
                    <Text
                      title="E-mail"
                      value={
                        loading ? (
                          <Skeleton animation="wave" />
                        ) : (
                          user?.userData.email
                        )
                      }
                    />
                    <Text
                      title="Celular"
                      value={
                        loading ? (
                          <Skeleton
                            animation="wave"
                            sx={{ width: 1 / 2 }}
                          />
                        ) : user?.userData.phone ? (
                          phoneFormat(user?.userData.phone ?? '')
                        ) : (
                          '-'
                        )
                      }
                    />
                    <Text
                      title="CPF"
                      value={
                        loading ? (
                          <Skeleton
                            animation="wave"
                            sx={{ width: 1 / 2 }}
                          />
                        ) : user?.userData.cpf ? (
                          cpfFormat(user?.userData.cpf)
                        ) : (
                          '-'
                        )
                      }
                    />
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Stack>
        {/* </Collapse> */}
      </Content>
    </>
  );
};
export default UserSupervisorDetail;
