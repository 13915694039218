import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Box,
  CircularProgress,
  Drawer,
  DrawerProps,
  useTheme,
} from '@mui/material';
import { isFunction } from 'lodash';

interface IDrawerItem {
  element: ReactNode | ((drawer: IDrawerItemWithIdentity) => ReactNode);
  onClose?(any: any): void | Promise<void>;
  options?: Omit<DrawerProps, 'open'>;
}

interface IDrawerContextProperties {
  open(drawer: IDrawerItem): void;
  closeAll(params?: IDrawerItemClose['params']): void;
}

interface IDrawerItemContextProperties {
  id: number;
  close(params?: IDrawerItemClose['params']): void;
}

interface IDrawerItemWithIdentity extends IDrawerItem {
  id: number;
}

interface IDrawerItemClose {
  id: IDrawerItemWithIdentity['id'];
  onClose?: IDrawerItemWithIdentity['onClose'];
  params?: any;
}

const DrawerContext = createContext<IDrawerContextProperties | undefined>(
  undefined
);

const DrawerItemContext = createContext<
  IDrawerItemContextProperties | undefined
>(undefined);

export const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (!context) throw new Error('drawer provider required');
  return context;
};

export const useItemDrawer = () => {
  const context = useContext(DrawerItemContext);
  if (!context) throw new Error('item drawer provider required');
  return context;
};

export const DrawerLoading = () => (
  <Box
    alignItems="center"
    display="flex"
    flex={1}
    flexGrow={1}
    justifyContent="center"
  >
    <CircularProgress color="primary" />
  </Box>
);

const DrawerProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const theme = useTheme();
  const drawerRef = useRef(0);
  const [drawers, setDrawers] = useState<IDrawerItemWithIdentity[]>([]);
  const [current, setCurrent] = useState<number[]>([]);

  const addDrawer = useCallback((item: IDrawerItem) => {
    const currentIdentity = drawerRef.current;
    drawerRef.current += 1;
    setDrawers((old) => {
      old.push({
        ...item,
        id: currentIdentity,
      });
      return [...old];
    });

    setTimeout(() => {
      setCurrent((old) => [...old, currentIdentity]);
    }, 0);
  }, []);

  const openDrawer = useCallback(
    (item: IDrawerItem) => {
      addDrawer(item);
    },
    [addDrawer]
  );

  const closeDrawer = useCallback(
    (drawerItem: IDrawerItemClose) => {
      setCurrent((old) => [...old.filter((index) => index !== drawerItem.id)]);

      setTimeout(() => {
        setDrawers((old) => [...old.filter((d) => d.id !== drawerItem.id)]);
        if (drawerItem.onClose)
          drawerItem.onClose(drawerItem.params ?? undefined);
      }, theme.transitions.duration.leavingScreen);
    },
    [theme]
  );

  const closeAll = useCallback(
    (params?: IDrawerItemClose['params']) => {
      drawers.forEach((drawer) => {
        closeDrawer({
          id: drawer.id,
          onClose: drawer.onClose,
          params,
        });
      });
    },
    [drawers, closeDrawer]
  );

  const props = useMemo(
    () => ({
      open: openDrawer,
      closeAll,
    }),
    [openDrawer, closeAll]
  );

  const propsItem = useCallback(
    (drawerItem: Omit<IDrawerItemWithIdentity, 'element'>) => ({
      id: drawerItem.id,
      close(params?: any) {
        closeDrawer({
          id: drawerItem.id,
          onClose: drawerItem.onClose,
          params,
        });
      },
    }),
    [closeDrawer]
  );

  return (
    <DrawerContext.Provider value={props}>
      {drawers.map((drawer: IDrawerItemWithIdentity, index) => (
        <Drawer
          anchor="right"
          key={drawer.id}
          PaperProps={{
            sx: {
              width: `calc(80vw - ${index * 100}px)`,
              minHeight: '100%',
            },
          }}
          {...drawer?.options}
          onClose={() => {
            closeDrawer({
              id: drawer.id,
              onClose: drawer.onClose,
            });
          }}
          open={current.includes(drawer.id)}
        >
          <DrawerItemContext.Provider
            value={propsItem({
              id: drawer.id,
              onClose: drawer.onClose,
            })}
          >
            {isFunction(drawer.element)
              ? drawer.element(drawer)
              : drawer.element}
          </DrawerItemContext.Provider>
        </Drawer>
      ))}
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
