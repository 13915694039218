import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { TextFieldElement } from 'react-hook-form-mui';

import { Box, Collapse, Grid, Stack } from '@mui/material';

import { getVideoCode } from '../helpers/youtube';

const ContentYoutubeForm = () => {
  const form = useFormContext();

  const videoUrl = form.watch('youtube.videoUrl');

  const videoUrlCode = useMemo(() => getVideoCode(videoUrl), [videoUrl]);

  return (
    <Box mt={2}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextFieldElement
            label="Titulo"
            name="youtube.title"
          />
        </Grid>
        {/* <Grid
          item
          md={12}
          xs={12}
        >
          <TextFieldElement
            label="Subtitulo"
            name="youtube.subtitle"
          />
        </Grid> */}
        <Grid
          item
          md={12}
        >
          <Stack spacing={2}>
            <TextFieldElement
              label="Link do Youtube"
              name="youtube.videoUrl"
            />
            <Collapse
              in={!!videoUrlCode}
              unmountOnExit
            >
              <Box>
                <iframe
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  frameBorder="0"
                  height="350"
                  src={`https://www.youtube.com/embed/${videoUrlCode}`}
                  title="YouTube video player"
                  width="100%"
                />
              </Box>
            </Collapse>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentYoutubeForm;
