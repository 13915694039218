import {
  cloneElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Checkbox, Menu, MenuItem } from '@mui/material';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import resources from '../../../resources';
import { ISupervisor } from '../../../resources/user';

interface ISupervisorFilter {
  onChange(supervisors: string[]): void;
  button:
    | ReactNode
    | (({
        supervisors,
      }: {
        supervisors: ISupervisor[];
        loading: boolean;
      }) => ReactNode);
  defaultValue?: string[];
  centers: string[];
  multiSelected?: boolean;
  showAll?: boolean;
}

const SupervisorFilter: React.FC<ISupervisorFilter> = ({
  onChange,
  defaultValue = [],
  multiSelected = true,
  centers = [],
  showAll = false,
  button,
}) => {
  const [supervisors, setSupervisors] = useState<ISupervisor[]>([]);
  const [selected, setSelected] = useState<string[]>(defaultValue ?? []);
  const [loadingSupervisors, setLoadingSupervisors] = useState(false);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'supervisor-filter',
  });

  const isSupervisorsSelected = useMemo(
    () => supervisors.every((c) => selected.includes(c.code)),
    [supervisors, selected]
  );

  const fetchSupervisors = useCallback(async () => {
    const response = await resources.use('user').getSupervisors({
      ...(centers.length > 0 ? { centers } : {}),
    });

    if (response.status === 'OK') {
      setSupervisors(response.payload ?? []);
    }
  }, [centers]);

  const buttonElement = useMemo(() => {
    if (typeof button === 'function')
      return button({ supervisors, loading: loadingSupervisors });
    return button;
  }, [button, loadingSupervisors, supervisors]);

  useEffect(() => {
    setLoadingSupervisors(true);
    fetchSupervisors().finally(() => {
      setLoadingSupervisors(false);
    });
  }, [fetchSupervisors]);

  return (
    <>
      {cloneElement(buttonElement as any, {
        ...bindTrigger(popupState),
      })}
      <Menu
        {...bindMenu(popupState)}
        PaperProps={{
          sx: { bgcolor: 'background.paper' },
        }}
      >
        {!!showAll && (
          <MenuItem
            onClick={() => {
              if (isSupervisorsSelected) {
                onChange([]);
                setSelected([]);
              } else {
                onChange(supervisors.map(({ code }) => code));
                setSelected(supervisors.map(({ code }) => code));
              }
            }}
          >
            <Checkbox
              checked={isSupervisorsSelected}
              disableRipple
              edge="start"
            />
            Todos
          </MenuItem>
        )}
        {(supervisors ?? []).map((t, key) => (
          <MenuItem
            key={key}
            onClick={() => {
              let newSelected;
              if (!selected.includes(t.code)) {
                if (multiSelected) {
                  newSelected = [
                    ...selected.filter((oo: string) => oo !== t.code),
                    t.code,
                  ];
                } else {
                  newSelected = [t.id];
                }
              } else {
                newSelected = [
                  ...selected.filter((oo: string) => oo !== t.code),
                ];
              }

              setSelected(newSelected);
              onChange(newSelected);
            }}
          >
            <Checkbox
              checked={selected.includes(t.code)}
              disableRipple
              edge="start"
            />
            {t.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SupervisorFilter;
