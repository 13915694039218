import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import logoUrl from '../assets/logo-pro-campo.svg';
import { AuthLayout } from '../components/layouts/auth';
import Contact from '../elements/contact';
import Auth from '../modules/auth';
import Content from '../modules/content';
import Dashboard from '../modules/dashboard';
import Notification from '../modules/notification';
import Report from '../modules/report';
import User from '../modules/user';

const AuthGuard = () => <AuthLayout />;

export default () => (
  <BrowserRouter>
    <Routes>
      <Route
        element={<Auth logoUrl={logoUrl} />}
        path="auth/*"
      />
      <Route element={<AuthGuard />}>
        <Route
          element={<Dashboard />}
          index
        />
        <Route
          element={<Report />}
          path="reports/*"
        />
        <Route
          element={<Content />}
          path="contents/*"
        />
        <Route
          element={<Notification />}
          path="notifications/*"
        />
        <Route
          element={<User />}
          path="users/*"
        />
        <Route
          element={<Contact />}
          path="contact"
        />
        <Route
          element={<Navigate to="/reports" />}
          path="*"
        />
      </Route>
    </Routes>
  </BrowserRouter>
);
