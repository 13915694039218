import React, { useMemo } from 'react';
import {
  AutocompleteElement,
  DatePickerElement,
  useFormContext,
} from 'react-hook-form-mui';

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  createFilterOptions,
  Grid,
  Paper,
  Stack,
} from '@mui/material';
import { contrastColor } from 'contrast-color';
import { format, isFuture, isValid } from 'date-fns';
import stringToColor from 'string-to-color';

import { TextProps } from '../../../components/editor-element';
import Validator from '../../../libraries/validator';
import { getVideoCode } from '../helpers/youtube';
import ContentFeedForm from './content-feed-form';
import ContentImageForm from './content-image-form';
import ContentPreview from './content-preview';
import ContentYoutubeForm from './content-youtube-form';

interface IContentForm {
  onImageUpload: TextProps['onUpload'];
  defaultTags?: string[];
  defaultCenters?: string[];
}

export const schemaValidation = Validator.object().shape({
  type: Validator.string()
    .nullable()
    .transform((v) => v ?? null)
    .oneOf(['feed', 'youtube', 'image'])
    .required()
    .label('Tipo'),
  lang: Validator.string()
    .nullable()
    .transform((v) => v ?? null)
    .oneOf(['pt_br', 'en', 'es'])
    .required()
    .label('Linguagem'),
  publishDate: Validator.mixed()
    .transform((v) => {
      if (!v) return null;
      if (String(v).includes('T')) return format(new Date(v), 'yyyy-MM-dd');
      return v;
    })
    .nullable()
    .required()
    .label('Data de Publicação'),
  codeCenter: Validator.array()
    .of(Validator.string())
    .min(1)
    .required()
    .label('Centro'),
  tags: Validator.array().default([]).of(Validator.string()).label('Tags'),
  feed: Validator.object().when(['type'], {
    is: (type: string) => type === 'feed',
    then: Validator.object().shape({
      title: Validator.string().required().label('Titulo'),
      imageUrl: Validator.mixed().required().label('Imagem'),
      content: Validator.string().nullable().required().label('Conteúdo'),
    }),
  }),
  youtube: Validator.object().when(['type'], {
    is: (type: string) => type === 'youtube',
    then: Validator.object().shape({
      title: Validator.string().required().label('Titulo'),
      videoUrl: Validator.string()
        .test(
          'url-link',
          'URL inválida (ex: https://www.youtube.com/watch?v=código)',
          (value) => !!value && getVideoCode(value) !== null
        )
        .required()
        .label('Código do Video'),
    }),
  }),
  image: Validator.object().when(['type'], {
    is: (type: string) => type === 'image',
    then: Validator.object().shape({
      title: Validator.string().required().label('Titulo'),
      imageUrl: Validator.mixed().required().label('URL da Imagem'),
    }),
  }),
});

const ContentForm: React.FC<IContentForm> = ({
  onImageUpload,
  defaultTags = [],
  defaultCenters = [],
}) => {
  const form = useFormContext();
  const type = form.watch('type');
  const tags = form.watch('tags');
  const publishDate = form.watch('publishDate');
  const centers: any[] = form.watch('codeCenter');

  const isFuturePublish = useMemo(
    () => isFuture(new Date(publishDate)),
    [publishDate]
  );

  const centerOptions = useMemo(() => {
    const centerValues = (centers ?? []).map((c) => c);

    const options = [];

    if (!centerValues.includes('Todos') && centerValues.length === 0) {
      options.push('Todos');
    }

    if (!centerValues.includes('Todos')) {
      (defaultCenters ?? [])
        .filter(
          (c) => !centerValues.includes(c) && !centerValues.includes('Todos')
        )
        .forEach((c) =>
          options.push(
            c
            // {
            // value: c,
            // label: capitalize(c),
            // }
          )
        );
    }

    return options;
  }, [defaultCenters, centers]);

  const tagOptions = defaultTags.filter((t) => !(tags ?? []).includes(t));

  const formValue = form.watch();

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        md={type ? 8 : 12}
      >
        <Box>
          <Stack
            direction="column"
            spacing={1}
          >
            <Paper sx={{ p: 3 }}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <AutocompleteElement
                    label="Tipo"
                    matchId
                    name="type"
                    options={[
                      {
                        label: 'Feed',
                        id: 'feed',
                      },
                      {
                        label: 'YouTube',
                        id: 'youtube',
                      },
                      {
                        label: 'Imagem',
                        id: 'image',
                      },
                    ]}
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <AutocompleteElement
                    label="Linguagem"
                    matchId
                    name="lang"
                    options={[
                      {
                        label: 'Português',
                        id: 'pt_br',
                      },
                      {
                        label: 'Inglês',
                        id: 'en',
                      },
                      {
                        label: 'Espanhol',
                        id: 'es',
                      },
                    ]}
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <DatePickerElement
                    label="Data de publicação"
                    minDate={new Date()}
                    name="publishDate"
                    parseDate={(date, keyboardInputValue) => {
                      if (!date && !keyboardInputValue) return null;
                      if (
                        keyboardInputValue?.length === 10 &&
                        (!isValid(date) ||
                          (isValid(date) &&
                            date < new Date(new Date().setHours(0, 0, 0, 0))))
                      ) {
                        return null;
                      }
                      return new Date(new Date(date).setHours(0, 0, 0, 0));
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <AutocompleteElement
                    autocompleteProps={{
                      ChipProps: { size: 'small' },
                      noOptionsText: 'Nenhuma opção encontrada',
                      loadingText: 'Carregando...',
                      renderTags(values, getTagProps) {
                        return (
                          <Stack
                            direction="row"
                            flexWrap="wrap"
                            spacing={1}
                          >
                            {values.map((value, key) => (
                              <Chip
                                label={value}
                                size="small"
                                {...getTagProps({ index: key })}
                                sx={{
                                  bgcolor: stringToColor(value),
                                  color: contrastColor({
                                    bgColor: stringToColor(value),
                                  }),
                                }}
                              />
                            ))}
                          </Stack>
                        );
                      },
                    }}
                    label="Centro"
                    multiple
                    name="codeCenter"
                    options={centerOptions}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <AutocompleteElement
                    autocompleteProps={{
                      freeSolo: true,
                      ChipProps: { size: 'small' },
                      clearOnBlur: true,
                      filterOptions: (options, params) => {
                        const filter = createFilterOptions();
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = [
                          ...options,
                          ...(form.getValues('tags') ?? []),
                        ].some((value) => inputValue === value);
                        if (inputValue !== '' && !isExisting) {
                          filtered.push(inputValue);
                        }
                        return filtered;
                      },
                      getOptionLabel: (option) => {
                        const isExisting = tagOptions.some(
                          (value) => option === value
                        );

                        if (typeof option === 'string' && !isExisting) {
                          return `Adicionar "${option}"`;
                        }
                        return option;
                      },
                      renderTags(values, getTagProps) {
                        return (
                          <Stack
                            direction="row"
                            flexWrap="wrap"
                            spacing={1}
                          >
                            {values.map((value, key) => (
                              <Chip
                                label={value}
                                size="small"
                                {...getTagProps({ index: key })}
                                sx={{
                                  bgcolor: stringToColor(value),
                                  color: contrastColor({
                                    bgColor: stringToColor(value),
                                  }),
                                }}
                              />
                            ))}
                          </Stack>
                        );
                      },
                    }}
                    label="Tags"
                    multiple
                    name="tags"
                    options={tagOptions}
                  />
                </Grid>
              </Grid>
              <Collapse
                in={type === 'feed'}
                unmountOnExit
              >
                <ContentFeedForm onImageUpload={onImageUpload} />
              </Collapse>
              <Collapse
                in={type === 'image'}
                unmountOnExit
              >
                <ContentImageForm />
              </Collapse>
              <Collapse
                in={type === 'youtube'}
                unmountOnExit
              >
                <ContentYoutubeForm />
              </Collapse>
            </Paper>
            <Button
              disabled={form.formState.isSubmitting}
              startIcon={
                form.formState.isSubmitting ? (
                  <CircularProgress size={10} />
                ) : null
              }
              sx={{ alignSelf: 'flex-end' }}
              type="submit"
              variant="contained"
            >
              {isFuturePublish ? 'Agendar Publicação' : 'Publicar'}
            </Button>
          </Stack>
        </Box>
      </Grid>
      {type && (
        <Grid
          item
          md={4}
        >
          <ContentPreview values={formValue} />
        </Grid>
      )}
    </Grid>
  );
};
export default ContentForm;
