import React from 'react';
import { Route, Routes } from 'react-router-dom';

import UserIndex from './elements/index';
import UserDashboard from './elements/user-dashboard';
import UserProviderDetail from './elements/user-dashboard/elements/provider-detail';
import UserSupervisorDetail from './elements/user-dashboard/elements/supervisor-detail';
import UserList from './elements/user-dashboard/elements/user-list';
import UserLayout from './elements/user-dashboard/layout';

const User: React.FC = () => (
  <Routes>
    <Route
      element={<UserIndex />}
      index
    />
    <Route
      element={<UserLayout />}
      path=":type"
    >
      <Route
        element={<UserDashboard />}
        index
      />
      <Route
        element={<UserList />}
        path=":context"
      />
      {/* <Route
        element={<CreateUser />}
        path="create"
      /> */}
      {/* <Route
      element={<UserUpdate />}
      path=":id"
    /> */}
    </Route>
    <Route
      element={<UserProviderDetail />}
      path="provider/:context/:cpf/detail"
    />
    <Route
      element={<UserSupervisorDetail />}
      path="supervisor/:context/:cpf/detail"
    />
  </Routes>
);

export default User;
